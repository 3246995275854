import { FC, ReactNode, useEffect, useRef } from 'react';

import { Grid } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import DevTools from 'src/components/DevTools/DevTools';
import EmploymentDateField from 'src/components/fields/Income/EmploymentDateField';
import EmploymentEndDateField from 'src/components/fields/Income/EmploymentEndDateField';
import EmploymentTypeField from 'src/components/fields/Income/EmploymentTypeField';
import NextStepButton from 'src/components/Stepper/common/NextStepButton';
import { useBlancoDataFormContext } from 'src/contexts/blanco/BlancoDataFormContext';
import { FormSectionContextProvider } from 'src/contexts/FormSectionContext';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';
import BlancoNorwayIncomeForm from 'src/types/BlancoNorway/form/BlancoNorwayIncomeForm';
import BlancoSwedenIncomeForm from 'src/types/BlancoSweden/form/BlancoSwedenIncomeForm';
import { showEmploymentEndDate, showEmploymentStartDate } from 'src/utils/employmentType/employmentTypeRequiredDates';
import getValueByPath from 'src/utils/lodashLike/getValueByPath';

interface BlancoEmploymentTypeSectionProps {
  coApplicant?: boolean;
}

const BlancoEmploymentTypeSection: FC<BlancoEmploymentTypeSectionProps> = (props) => {
  const { coApplicant } = props;
  const { formData, updateFormField } = useBlancoDataFormContext();
  const { schemaFields } = useYupSchemaContext();
  const bottomRef = useRef(null);

  const validationSchema = Yup.object({
    employmentType: schemaFields.income.employmentType,
    employmentStartDateMonth: schemaFields.income.employmentStartDateMonth,
    employmentStartDateYear: schemaFields.income.employmentStartDateYear,
    employmentEndDateMonth: schemaFields.income.employmentEndDateMonth,
    employmentEndDateYear: schemaFields.income.employmentEndDateYear,
  });


  const key = !coApplicant ? 'income' : 'coApplicant.income';
  const data = getValueByPath(formData, key) as BlancoSwedenIncomeForm | BlancoNorwayIncomeForm;

  const initialValues = {
    employmentType: data.employmentType,
    employmentStartDateMonth: data.employmentStartDate?.month,
    employmentStartDateYear: data.employmentStartDate?.year,
    employmentEndDateMonth: data.employmentEndDate?.month,
    employmentEndDateYear: data.employmentEndDate?.year,
  };


  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => { }}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({ values }): ReactNode => {
        const showStartDate = showEmploymentStartDate(values.employmentType);
        const showEndDate = showEmploymentEndDate(values.employmentType);

        useEffect(() => {
          setTimeout(() => bottomRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' }), 250);
        }, [showStartDate, showEndDate]);

        const updateForm = () => {
          updateFormField(`${key}.employmentType`, values.employmentType);
          updateFormField(`${key}.employmentStartDate`, { month: values.employmentStartDateMonth, year: values.employmentStartDateYear });
          updateFormField(`${key}.employmentEndDate`, { month: values.employmentEndDateMonth, year: values.employmentEndDateYear });
        };

        return (
          <FormSectionContextProvider
            updateFormContextCallback={() => updateForm()}
            heading={!coApplicant ? 'What is your employment type?' : 'What is your co-applicant\'s employment type?'}
          >
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <EmploymentTypeField
                  name='employmentType'
                  coApplicant={coApplicant}
                />
              </Grid>
              {showStartDate && (
                <Grid
                  item
                  xs={12}
                >
                  <EmploymentDateField
                    monthName='employmentStartDateMonth'
                    yearName='employmentStartDateYear'
                    coApplicant={coApplicant}
                  />
                </Grid>
              )}
              {showEndDate && (
                <Grid
                  item
                  xs={12}
                >
                  <EmploymentEndDateField
                    monthName='employmentEndDateMonth'
                    yearName='employmentEndDateYear'
                    coApplicant={coApplicant}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                ref={bottomRef}
              >
                <NextStepButton
                  alwaysVisible
                />
              </Grid>
              <DevTools />
            </Grid>
          </FormSectionContextProvider>
        );
      }}
    </Formik>
  );
};


export default BlancoEmploymentTypeSection;
