import { FC } from 'react';

import { ArrowForward } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useFormikContext } from 'formik';

import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';

interface NextStepButtonProps {
  updateFormContextCallback: () => void;
}

const NextStepButton: FC<NextStepButtonProps> = (props) => {
  const { updateFormContextCallback } = props;

  const { validateForm } = useFormikContext();
  const { setCurrentStep } = useFormStepperDialogContext();

  const validateAndOpenDialog = async () => {
    const temp = await validateForm();

    if (Object.keys(temp).length === 0) {
      updateFormContextCallback();
      setCurrentStep(p => p + 1);
    }
  };

  return (
    <Button
      variant="contained" 
      onClick={() => validateAndOpenDialog()} 
      endIcon={<ArrowForward />}
      sx={{ width: '100%', mt: 3 }}
      size='large'
    >
      Next step
    </Button>
  );
};

export default NextStepButton;
