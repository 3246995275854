import { FC } from 'react';

import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

interface ConsentCheckboxProps {
  name: string;
}

const ConsentCheckbox: FC<ConsentCheckboxProps> = (props) => {
  const { name } = props;

  const { values, setFieldValue, errors } = useFormikContext<{ [key: string]: any; }>();

  const error = errors[name] as string;

  return (
    <Box>
      <FormControlLabel
        checked={values[name]}
        color="primary"
        control={<Checkbox />}
        onChange={() => setFieldValue(name, !values[name])}
        label={'Yes, I consent to the processing of my personal data'}
      />
      {error && (
        <Typography variant='caption' color='error' sx={{ px: 4 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
};


export default ConsentCheckbox;
