import { FC, useEffect } from 'react';

import { Container } from '@mui/material';

import FormEntryCard from 'src/components/FormEntry/FormEntryCard';
import { useAuthContext } from 'src/contexts/auth/AuthContext';
import { MarketingConsentContextProvider } from 'src/contexts/consents/MarketingConsentContext';
import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';

import GrFirstAppCreatedPage from './GrFirstAppCreatedPage';
import GrFirstCredits from './GrFirstCredits';
import GrFirstStartPage from './GrFirstStartPage';

interface GrFirstProps { }

const GrFirst: FC<GrFirstProps> = (props) => {
  const { } = props;

  const { checkBankIdNoAuth, authState, authFinished } = useAuthContext();
  const { appIsCreated } = useFormStepperDialogContext();

  useEffect(() => {
    checkBankIdNoAuth();
  }, []);

  if (!authFinished) {
    return <></>;
  }

  return (
    <>
      {authState?.authenticated && (
        <>
          {appIsCreated && (
            <GrFirstAppCreatedPage />
          )}
          {!appIsCreated && (
            <MarketingConsentContextProvider>
              <GrFirstCredits />
            </MarketingConsentContextProvider>
          )}
        </>
      )}
      {!authState?.authenticated && (
        <Container maxWidth='sm'>
          <FormEntryCard>
            <GrFirstStartPage />
          </FormEntryCard>
        </Container>
      )}
    </>
  );
};


export default GrFirst;
