
import { FC, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-redeclare
import history from 'history/browser';

import FormSelector from './components/FormEntry/FormSelector';
import { FormSelectorContextProvider } from './contexts/FormSelectorContext';
import { FormStepperDialogContextProvider } from './contexts/FormStepperDialogContext';
import { useSettingsContext } from './contexts/SettingsContext';
import { SharedDataContextProvider } from './contexts/SharedDataContext';
import GrFirst from './gr/GrFirst/GrFirst';
import OnlineFormType from './types/OnlineFormType';

interface AppProps {
  onlineFormType: OnlineFormType;
}

const App: FC<AppProps> = (props) => {
  const { onlineFormType } = props;

  const { grFirst } = useSettingsContext();

  // Fix edge case where user navigates to page via URL with steps in pathname
  useEffect(() => {
    if (/^\#step\d*-\d+/.test(history.location.hash) || /^\#steg\d*-\d+/.test(history.location.hash)) {
      history.replace({
        pathname: history.location.pathname,
        search: history.location.search,
      });
    }
  }, []);

  return (
    <SharedDataContextProvider>
      {grFirst && (
        <FormSelectorContextProvider onlineFormType={onlineFormType}>
          <FormStepperDialogContextProvider>
            <GrFirst />
          </FormStepperDialogContextProvider>
        </FormSelectorContextProvider>
      )}
      {!grFirst && (
        <FormSelectorContextProvider onlineFormType={onlineFormType}>
          <FormStepperDialogContextProvider>
            <FormSelector />
          </FormStepperDialogContextProvider>
        </FormSelectorContextProvider>
      )}
    </SharedDataContextProvider>
  );
};

export default App;
