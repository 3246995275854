import { FC } from 'react';

import { Grid,  Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import MonthField from '../base/MonthField';
import YearField from '../base/YearField';

interface RetirementDateFieldProps {
}

const RetirementDateField: FC<RetirementDateFieldProps> = (props) => {
  const { } = props;

  const { errors } = useFormikContext<{ [key: string]: any; }>();

  const error = errors.newLoanPurpose as string;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>
          What is your retirement date?
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <MonthField name='retiredMonth' />
      </Grid>
      <Grid item xs={6}>
        <YearField name='retiredYear' />
      </Grid>
      {error && (
        <Grid item xs={12}>
          <Typography variant='caption' color='error'>
            {error}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};


export default RetirementDateField;
