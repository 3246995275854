import { FC } from 'react';

import { Grid,  Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import StyledToggleButton from 'src/components/buttons/toggle/StyledToggleButton';
import StyledToggleButtonGroup from 'src/components/buttons/toggle/StyledToggleButtonGroup';
import BlancoSwedenEmploymentType from 'src/types/BlancoSweden/BlancoSwedenEmploymentType';

interface EmploymentTypeFieldProps {
  name: string;
}

const EmploymentTypeField: FC<EmploymentTypeFieldProps> = (props) => {
  const { name } = props;

  const { values, setFieldValue, errors } = useFormikContext<{ [key: string]: any; }>();

  const error = errors.newLoanPurpose as string;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>
          What is your employment type?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <StyledToggleButtonGroup
          value={values[name]}
          exclusive
          onChange={(e, value) => {
            if (value !== null)
              setFieldValue(name, value);
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={3}>
                <StyledToggleButton value={BlancoSwedenEmploymentType.FullTime}>
                  Full Time
                </StyledToggleButton>
              </Grid>
              <Grid item xs={3}>
                <StyledToggleButton value={BlancoSwedenEmploymentType.Hourly}>
                  Hourly
                </StyledToggleButton>
              </Grid>
              <Grid item xs={3}>
                <StyledToggleButton value={BlancoSwedenEmploymentType.SelfEmployed}>
                  Self-Employed
                </StyledToggleButton>
              </Grid>
              <Grid item xs={3}>
                <StyledToggleButton value={BlancoSwedenEmploymentType.Trial}>
                  Trial
                </StyledToggleButton>
              </Grid>
              <Grid item xs={3}>
                <StyledToggleButton value={BlancoSwedenEmploymentType.ProjectStandin}>
                  Project / Standin
                </StyledToggleButton>
              </Grid>
              <Grid item xs={3}>
                <StyledToggleButton value={BlancoSwedenEmploymentType.Retired}>
                  Retired
                </StyledToggleButton>
              </Grid>
              <Grid item xs={3}>
                <StyledToggleButton value={BlancoSwedenEmploymentType.EarlyRetired}>
                  Early Retired
                </StyledToggleButton>
              </Grid>
              {error && (
                <Grid item xs={12}>
                  <Typography variant='caption' color='error'>
                    {error}
                  </Typography>
                </Grid>
              )}
          </Grid>
        </StyledToggleButtonGroup>
      </Grid>
    </Grid>
  );
};


export default EmploymentTypeField;
