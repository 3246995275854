import { FC } from 'react';

import { Add, Remove } from '@mui/icons-material';
import { Box, styled, TextField, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import CircleButton from '../buttons/CircleButton';

const ParentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(1),
}));

interface RepaymentTimeFieldProps {
  name: string;
}

const min = 1;
const max = 20;

const RepaymentTimeField: FC<RepaymentTimeFieldProps> = (props) => {
  const { name } = props;

  const { values, handleChange, setFieldValue } = useFormikContext<{ [key: string]: any; }>();


  return (
      <ParentBox>
        <Typography sx={{ flex: 1 }}>
          Repayment Time:
        </Typography>
        <CircleButton 
          variant='contained' 
          color='primary'
          onClick={() => values[name] > min ? setFieldValue(name, values[name] - 1) : setFieldValue(name, min)}
          size='small'
        >
          <Remove />
        </CircleButton>
        <TextField
          size='small'
          InputProps={{ endAdornment: 'years' }}
          value={values[name]}
          onChange={handleChange}
          className="no-spinners"
          onBlur={(e) => {
            let value = parseInt(e.target.value, 10);

            if (value < min) value = min;
            if (value > max) value = max;

            setFieldValue(name, value);
          }}
          name={name}
          type='number'
          sx={{ width: '100px' }}
        />
        <CircleButton 
          variant='contained'
          color='primary' 
          onClick={() => values[name] < max ? setFieldValue(name, values[name] + 1) : setFieldValue(name, max)}
          size='small'
         >
          <Add />
        </CircleButton>
      </ParentBox>
  );
};


export default RepaymentTimeField;
