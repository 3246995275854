import { FC } from 'react';

import { MenuItem,  TextField } from '@mui/material';
import { useFormikContext } from 'formik';

interface MonthFieldProps {
  name: string;
}

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const MonthField: FC<MonthFieldProps> = (props) => {
  const { name } = props;

  const { values, handleChange } = useFormikContext<{ [key: string]: any; }>();

  return (
    <TextField
      select
      label="Month"
      value={values[name]}
      onChange={handleChange}
      name={name}
      fullWidth
    >
      {months.map((month, index) => (
        <MenuItem key={index} value={index}>
          {month}
        </MenuItem>
      ))}
    </TextField>
  );
};


export default MonthField;
