import { Dispatch, FC, SetStateAction } from 'react';

import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface GrConsentCheckboxProps {
  value: boolean;
  setValue: Dispatch<SetStateAction<boolean>>;
  error: boolean;
}

const GrConsentCheckbox: FC<GrConsentCheckboxProps> = (props) => {
  const { value, setValue, error } = props;

  const { t } = useTranslation();

  return (
    <Box>
      <FormControlLabel
        checked={value}
        control={
          <Checkbox
            size='small'
          />
        }
        onChange={() => setValue(p => !p)}
        label={
          <Typography
            fontSize='small'
            sx={{ m: 0 }}
          >
            {t('I consent to Zensum AS retrieving and storing my debt data for 12 months in accordance with applicable legislation. The data will be analyzed and used to maximize opportunities to improve my loan situation.')}
          </Typography>
        }
        sx={{ width: '100%' }}
      />
      {error && (
        <Typography
          variant='caption'
          color='error'
          sx={{ px: 3.5 }}
        >
          {t('Required')}
        </Typography>
      )}
    </Box>
  );
};


export default GrConsentCheckbox;
