import { createContext, useMemo, ReactNode, useContext  } from 'react';

import { Api } from 'src/api/zrm';

type BearerToken = string;
export type ZRMApi = Api<BearerToken>;

interface APIs {
  api: ZRMApi,
}
interface Props {
  children?: ReactNode
}

const APIContext = createContext<APIs>(null);

export const APIProvider = ({ children }: Props) => {
   
  const value = useMemo((): APIs => {
     
    const baseApiParams = { secure: true, headers:  {} };

    return {
      api: new Api({
        baseUrl: process.env.REACT_APP_ZRM_URL,
        baseApiParams,
      }),
    };
  }, []);

  return (
    <APIContext.Provider value={value}>
      {children}
    </APIContext.Provider>
  );
};

export default APIContext;

export const useApi = () => useContext(APIContext);
