import { FC } from 'react';

import { Grid,  Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import StyledToggleButton from 'src/components/buttons/toggle/StyledToggleButton';
import StyledToggleButtonGroup from 'src/components/buttons/toggle/StyledToggleButtonGroup';


interface MaritalStatusFieldProps {
  name: string;
}

const MaritalStatusField: FC<MaritalStatusFieldProps> = (props) => {
  const { name } = props;

  const { values, setFieldValue, errors } = useFormikContext<{ [key: string]: any; }>();

  const error = errors[name] as string;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography>
          What is your marital status?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <StyledToggleButtonGroup
          value={values[name]}
          exclusive
          onChange={(e, value) => {
            if (value !== null)
              setFieldValue(name, value);
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <StyledToggleButton value="Single">
              Single
              </StyledToggleButton>
            </Grid>
            <Grid item xs={3}>
              <StyledToggleButton value="Divorced">
              Divorced
              </StyledToggleButton>
            </Grid>
            <Grid item xs={3}>
              <StyledToggleButton value="Widow/Widower">
              Widow/Widower
              </StyledToggleButton>
            </Grid>
            <Grid item xs={3}>
              <StyledToggleButton value="Married/Partner">
              Married/Partner
              </StyledToggleButton>
            </Grid>
          </Grid>
        </StyledToggleButtonGroup>
      </Grid>

      {error && (
        <Grid item xs={12}>
          <Typography variant='caption' color="error">
            {error}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};


export default MaritalStatusField;
