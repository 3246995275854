import { FC, useState } from 'react';

import { Check } from '@mui/icons-material';
import { Button, ButtonProps, Snackbar } from '@mui/material';

const FinishButton: FC<ButtonProps> = (props) => {

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        variant="contained" 
        startIcon={<Check />}
        sx={{ width: '100%', mt: 3 }}
        size='large'
        onClick={() => setOpen(true)}
        {...props}
      >
        Finish
      </Button>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message="TODO: SEND DATA TO BACKEND"
      />
    </>
  );
};

export default FinishButton;
