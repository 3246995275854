import { FC, useMemo } from 'react';

import { Card, SxProps, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Country } from 'src/api/zrm';
import ExternallyVerifiedCreditExtra from 'src/types/GR/ExternallyVerifiedCreditExtra';
import camelCaseToText from 'src/utils/format/camelCaseToText';
import formatCurrency from 'src/utils/format/formatCurrency';
import formatPercent from 'src/utils/format/formatPercent';
import isEmpty from 'src/utils/lodashLike/isEmpty';



interface GrCreditDesktopTableProps {
  credits: ExternallyVerifiedCreditExtra[];
}

const GrCreditDesktopTable: FC<GrCreditDesktopTableProps> = (props) => {
  const { credits } = props;

  const { t } = useTranslation();
  const theme = useTheme();


  const getTableRenderer: (isLoan: boolean) => Array<{
    title: string;
    dataIndex: string;
    renderer: (row: ExternallyVerifiedCreditExtra) => string | number;
    hidden?: boolean;
    sx?: SxProps;
  }> = useMemo(() => (isLoan: boolean) => {
    return [
      {
        title: t('Creditor'),
        dataIndex: 'financialInstitutionName',
        renderer: (row: ExternallyVerifiedCreditExtra) => row.financialInstitutionName,
        sx: { color: theme.palette.primary.main, fontWeight: 'bold' },
      },
      {
        title: t('Loan Type'),
        dataIndex: 'gjeldsregisteret_type',
        renderer: (row: ExternallyVerifiedCreditExtra) => t(camelCaseToText(row.gjeldsregisteret_type)),
      },
      {
        title: t('Interest Bearing Amount'),
        dataIndex: 'interestBearingBalance',
        renderer: (row: ExternallyVerifiedCreditExtra) => (!isEmpty(row.interestBearingBalance ?? row.balance) ? formatCurrency(row.interestBearingBalance ?? row.balance, undefined, 0) : '---'),
      },
      {
        title: t('Non-Interest Bearing Amount'),
        dataIndex: 'nonInterestBearingBalance',
        renderer: (row: ExternallyVerifiedCreditExtra) => formatCurrency(row.nonInterestBearingBalance ?? 0, Country.NO, 0),
        hidden: isLoan,
      },
      {
        title: t('Loan pay-off time'),
        dataIndex: 'terms',
        renderer: (row: ExternallyVerifiedCreditExtra) => (!isEmpty(row.terms) ? t('{{diff}} months', { diff: row.terms }) : '---'),
        hidden: !isLoan,
      },
      {
        title: t('Rate'),
        dataIndex: 'nominalInterestRate',
        renderer: (row: ExternallyVerifiedCreditExtra) => (!isEmpty(row.nominalInterestRate) ? formatPercent(row.nominalInterestRate, Country.NO, 2) : '---'),
      },
      {
        title: t('Fees'),
        dataIndex: 'installmentCharges',
        renderer: (row: ExternallyVerifiedCreditExtra) => (!isEmpty(row.installmentCharges) ? formatCurrency(row.installmentCharges, Country.NO, 0) : '---'),
      },
      {
        title: t('Monthly Payment'),
        dataIndex: 'monthly_payment',
        renderer: (row: ExternallyVerifiedCreditExtra) => formatCurrency(row.monthly_payment ?? 0, Country.NO, 0),
        hidden: !isLoan,
      },
      {
        title: t('Estimated Monthly Payment'),
        dataIndex: 'monthly_payment',
        renderer: (row: ExternallyVerifiedCreditExtra) => formatCurrency(row.monthly_payment ?? 0, Country.NO, 0),
        hidden: isLoan,
      },
    ].filter(x => !x.hidden);
  }, [t, formatCurrency, formatPercent, theme.palette.primary.main]);

  const loanCreditsRenderer = getTableRenderer(true);
  const notLoanCreditsRenderer = getTableRenderer(false);

  const loanCredits = credits.filter(credit => credit.gjeldsregisteret_type === 'repaymentLoan');
  const notLoanCredits = credits.filter(credit => credit.gjeldsregisteret_type !== 'repaymentLoan');

  const loanCreditsSum = loanCredits.reduce((acc, curr) => ({
    interestBearingBalance: acc.interestBearingBalance + (curr.interestBearingBalance ?? curr.balance),
    nonInterestBearingBalance: acc.nonInterestBearingBalance + curr.nonInterestBearingBalance,
    installmentCharges: acc.installmentCharges + curr.installmentCharges,
    monthly_payment: acc.monthly_payment + curr.monthly_payment,
  }), {
    interestBearingBalance: 0,
    nonInterestBearingBalance: 0,
    installmentCharges: 0,
    monthly_payment: 0,
  }
  ) as Record<string, number>;

  const notLoanCreditsSum = notLoanCredits.reduce((acc, curr) => ({
    interestBearingBalance: acc.interestBearingBalance + (curr.interestBearingBalance ?? curr.balance),
    nonInterestBearingBalance: acc.nonInterestBearingBalance + curr.nonInterestBearingBalance,
    installmentCharges: acc.installmentCharges + curr.installmentCharges,
    monthly_payment: acc.monthly_payment + curr.monthly_payment,
  }), {
    interestBearingBalance: 0,
    nonInterestBearingBalance: 0,
    installmentCharges: 0,
    monthly_payment: 0,
  }
  ) as Record<string, number>;

  return (
    <Card>
      <Table sx={{ mb: 5 }}>
        {!!loanCredits.length && (
          <>
            <TableHead>
              <TableRow>
                {loanCreditsRenderer.map((column) => (
                  <TableCell
                    key={`gr-table-header-${column.dataIndex}`}
                  >
                    {column.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loanCredits?.map((credit: ExternallyVerifiedCreditExtra) => (
                <TableRow
                  key={`gr-table-${credit.uuid}`}
                >
                  {loanCreditsRenderer.map((column) => (
                    <TableCell
                      key={`gr-table-col-${credit.uuid}-${column.dataIndex}`}
                      sx={{ ...column.sx }}
                    >
                      {column.renderer(credit)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              <TableRow>
                {loanCreditsRenderer.map((column) => (
                  <TableCell
                    key={`gr-table-col-summary-${column.dataIndex}`}
                    sx={{ fontWeight: 'bold' }}
                  >
                    {!isEmpty(loanCreditsSum[column.dataIndex]) ? formatCurrency(loanCreditsSum[column.dataIndex], Country.NO, 0) : ''}
                  </TableCell>
                ))}
              </TableRow>
              {!!notLoanCredits.length && (
                <TableRow>
                  {loanCreditsRenderer.map((column) => (
                    <TableCell
                      key={`gr-table-col-${column.dataIndex}-empty`}
                      sx={{ height: '50px' }}
                    />
                  ))}
                </TableRow>
              )}
            </TableBody>
          </>
        )}
        {!!notLoanCredits.length && (
          <>
            <TableHead>
              <TableRow>
                {notLoanCreditsRenderer.map((column) => (
                  <TableCell
                    key={`gr-table2-header-${column.dataIndex}`}
                  >
                    {column.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {notLoanCredits?.map((credit: ExternallyVerifiedCreditExtra) => (
                <TableRow
                  key={`gr-table2-${credit.uuid}`}
                >
                  {notLoanCreditsRenderer.map((column) => (
                    <TableCell
                      key={`gr-table2-col-${credit.uuid}-${column.dataIndex}`}
                      sx={{ ...column.sx }}
                    >
                      {column.renderer(credit)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              <TableRow>
                {notLoanCreditsRenderer.map((column) => (
                  <TableCell
                    key={`gr-table2-col-summary-${column.dataIndex}`}
                    sx={{ fontWeight: 'bold' }}
                  >
                    {notLoanCreditsSum[column.dataIndex] ? formatCurrency(notLoanCreditsSum[column.dataIndex], Country.NO, 0) : ''}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </>)}
      </Table>
    </Card>
  );
};


export default GrCreditDesktopTable;
