import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react';

interface FormStepperDialogContextProps {
  open: boolean;
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  openDialog: () => void;
  closeDialog: () => void;
}

interface FormStepperDialogContextProviderProps {
  children: ReactNode;
}

const FormStepperDialogContext = createContext<FormStepperDialogContextProps>({
  open: false,
  currentStep: 0,
  closeDialog: () => {},
  openDialog: () => {},
  setCurrentStep: () => {},
});

const FormStepperDialogContextProvider: FC<FormStepperDialogContextProviderProps> = (props) => {
  const { children } = props;

  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };
  
  return (
    <FormStepperDialogContext.Provider
      value={{
        open,
        currentStep,
        openDialog,
        closeDialog,
        setCurrentStep,
      }}
    >
      {children}
    </FormStepperDialogContext.Provider>
  );
};

const useFormStepperDialogContext = () => useContext(FormStepperDialogContext);

export { FormStepperDialogContextProvider, useFormStepperDialogContext };
