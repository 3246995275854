import { FC } from 'react';

import { Grid,  Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import CurrencyField from '../base/CurrencyField';

interface LivingCostFieldProps {
  name: string;
}

const LivingCostField: FC<LivingCostFieldProps> = (props) => {
  const { name } = props;

  const { values, errors } = useFormikContext<{ [key: string]: any; }>();

  const error = errors[name] as string;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography>
          What is your living cost?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CurrencyField
          value={values[name]}
          name={name}
          error={!!error}
        />
      </Grid>
      {error && (
        <Grid item xs={12}>
          <Typography variant='caption' color="error">
            {error}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};


export default LivingCostField;
