import { ThemeOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface TypeBackground {
    light: string;
  }
}

// TODO: Add more theme options & proper colors
const defaultThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#068466',
      light: '#e0ece8',
      dark: '#004930',
      contrastText: '#fff',
    },
    secondary: {
      main: '#670684',
      light: '#b55fb6',
      dark: '#490176',
      contrastText: '#fff',
    },
  },
};

const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    background: {
      light: '#f5f5f5',
    },
  },
};

const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    background: {
      light: '#121212',
    },
  },
};

export { defaultThemeOptions, lightThemeOptions, darkThemeOptions };
