import { FC } from 'react';

import { Grid,  TextField,  Typography } from '@mui/material';
import { useFormikContext } from 'formik';

interface PNIFieldProps {
  name: string;
}

const PNIField: FC<PNIFieldProps> = (props) => {
  const { name } = props;

  const { values, handleChange, errors } = useFormikContext<{ [key: string]: any; }>();

  const error = errors[name] as string;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          value={values[name]}
          onChange={handleChange}
          name={name}
          label="Personal Number"
          error={!!error}
        />
      </Grid>
      {error && (
        <Grid item xs={12}>
          <Typography variant='caption' color="error">
            {error}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};


export default PNIField;
