import { FC } from 'react';

import FormStepper from 'src/components/Stepper/FormStepper';
import SingleStep from 'src/types/Stepper/SingleStep';

import BlancoSwedenContactSection from './sections/BlancoSwedenContactSection';
import BlancoSwedenFamilySection from './sections/BlancoSwedenFamilySection';
import BlancoSwedenIncomeSection from './sections/BlancoSwedenIncomeSection';
import BlancoSwedenLivingSituationSection from './sections/BlancoSwedenLivingSituationSection';
import BlancoSwedenSummarySection from './sections/BlancoSwedenSummarySection';

const steps: SingleStep[] = [
  { 
    label: 'Family', 
    component: <BlancoSwedenFamilySection />,
  },
  { 
    label: 'Living Situation', 
    component: <BlancoSwedenLivingSituationSection />,
  },
  { 
    label: 'Income', 
    component: <BlancoSwedenIncomeSection />,
  },
  { 
    label: 'Contact', 
    component: <BlancoSwedenContactSection />,
  },
  { 
    label: 'Summary', 
    component: <BlancoSwedenSummarySection />,
  },
];

interface BlancoSwedenFormStepperProps {}

const BlancoSwedenFormStepper: FC<BlancoSwedenFormStepperProps> = (props) => {
  const { } = props;

  return (
    <>
      <FormStepper steps={steps}/>
    </>
  );
};


export default BlancoSwedenFormStepper;
