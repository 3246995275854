import 'moment/locale/en-gb';
import 'moment/locale/fi';
import 'moment/locale/nb';
import 'moment/locale/sv';

import type { FC, ReactNode } from 'react';
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';

import moment from 'moment';

import { Country, Product, ZCountry, ZProduct } from 'src/api/zrm/zrmApi';
import i18n from 'src/i18n';
import OnlineFormType from 'src/types/OnlineFormType';
import { zCountryToCountry, zProductToProduct } from 'src/utils/zoordinates';

interface SettingsContextProps {
  country: Country;
  product: Product;
  selectedLanguage: string;
  setSelectedLanguage: Dispatch<SetStateAction<string>>;
}

interface SettingsProviderProps {
  onlineFormType: OnlineFormType;
  children?: ReactNode;
}

const SettingsContext = createContext<SettingsContextProps>({
  country: Country.SE,
  product: Product.Blanco,
  selectedLanguage: 'sv',
  setSelectedLanguage: () => {},
});

export const SettingsProvider: FC<SettingsProviderProps> = (props) => {
  const { onlineFormType, children } = props;

  const [country, setCountry] = useState<Country>(Country.SE);
  const [product, setProduct] = useState<Product>(Product.Blanco);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('sv');

  useEffect(() => {
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
      moment.locale(selectedLanguage);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    if (onlineFormType) {
      const p = onlineFormType.slice(2, 4).toUpperCase() as ZProduct;
      const c = onlineFormType.slice(0, 2).toUpperCase() as ZCountry;

      setCountry(zCountryToCountry(c));
      setProduct(zProductToProduct(p));
    }
  }, [onlineFormType]);

  const value = useMemo(() => ({
    country,
    product,
    selectedLanguage,
    setSelectedLanguage,
  }), []);

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;

export const useSettingsContext = () => useContext(SettingsContext);
