import { FC } from 'react';

import { Box } from '@mui/material';

import FormStepper from 'src/components/Stepper/FormStepper';
import SingleStep from 'src/types/Stepper/SingleStep';


const steps: SingleStep[] = [
  { 
    label: 'Family', 
    component: <Box />,
  },
  { 
    label: 'Living Situation', 
    component: <Box />,
  },
  { 
    label: 'Income', 
    component: <Box />,
  },
  { 
    label: 'Loans', 
    component: <Box />,
  },
  { 
    label: 'Contact', 
    component: <Box />,
  },
  { 
    label: 'Summary', 
    component: <Box />,
  },
];

interface BlancoNorwayFormStepperProps {}

const BlancoNorwayFormStepper: FC<BlancoNorwayFormStepperProps> = (props) => {
  const { } = props;

  return (
    <>
      <FormStepper steps={steps}/>
    </>
  );
};


export default BlancoNorwayFormStepper;
