import { FC } from 'react';

import { Grid, TextField,  Typography } from '@mui/material';
import { useFormikContext } from 'formik';

interface LivingCostFieldProps {
  name: string;
}

const EmployerNameField: FC<LivingCostFieldProps> = (props) => {
  const { name } = props;

  const { values, handleChange, errors } = useFormikContext<{ [key: string]: any; }>();

  const error = errors[name] as string;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography>
          What is your employer name?
        </Typography>
      </Grid>
      <Grid item xs={12}>
      <TextField
          value={values[name]}
          onChange={handleChange}
          name={name}
          fullWidth
        />
      </Grid>
      {error && (
        <Grid item xs={12}>
          <Typography variant='caption' color="error">
            {error}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};


export default EmployerNameField;
