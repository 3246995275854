import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { Action } from 'history';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import history from 'history/browser';
import { useTranslation } from 'react-i18next';

import { OnlineFormEventType, OnlineFormEventSubType } from 'src/api/zrm';
import { useAnalyticsContext } from 'src/contexts/AnalyticsContext';
import SingleStep from 'src/types/Stepper/SingleStep';
import SkipStep from 'src/types/Stepper/SkipStep';

import { useSharedDataContext } from './SharedDataContext';

interface FormStepperDialogContextProps {
  open: boolean;
  appIsCreated: boolean;
  currentStep: number;
  currentSubStep: number;
  showNextStepButton: boolean;
  setSteps: Dispatch<SetStateAction<SingleStep[]>>;
  openDialog: (options?: { manualOpen?: boolean }) => void;
  closeDialog: (options?: { appCreated?: boolean, manualClose?: boolean }) => void;
  goToNextStep: (triggerHistory?: boolean) => void;
  goToPreviousStep: (triggerHistory?: boolean) => void;
  setSkippableSteps: Dispatch<SetStateAction<SkipStep[]>>;
  backwards: boolean;
  resetAppCreatedState: () => void;
}

interface FormStepperDialogContextProviderProps {
  children: ReactNode;
}

const FormStepperDialogContext = createContext<FormStepperDialogContextProps>({
  open: false,
  appIsCreated: false,
  currentStep: 0,
  currentSubStep: 0,
  showNextStepButton: true,
  closeDialog: () => { },
  openDialog: () => { },
  setSteps: () => { },
  goToNextStep: () => { },
  goToPreviousStep: () => { },
  setSkippableSteps: () => { },
  backwards: false,
  resetAppCreatedState: () => { },
});

const FormStepperDialogContextProvider: FC<FormStepperDialogContextProviderProps> = (props) => {
  const { children } = props;

  const { t } = useTranslation();
  const { formData } = useSharedDataContext();
  const { analyticsAddEvent, analyticsUpdateFormSteps } = useAnalyticsContext();

  const [open, setOpen] = useState(false);
  const [maxSubSteps, setMaxSubSteps] = useState(0);
  const [appIsCreated, setAppIsCreated] = useState(false);
  const [steps, setSteps] = useState<SingleStep[]>([]);
  const [skippableSteps, setSkippableSteps] = useState<SkipStep[]>([]);
  const [furthestStep, setFurthestStep] = useState({ step: 0, subStep: 0 });
  const [backwards, setBackwards] = useState(false);
  // Because we use `useRef` for some states, re-renders are not always triggered - we need to do it manually
  // `useReducer` is an alternative to `useState`, we don't want to have `useState` that holds some random value that we won't use
  const [, forceUpdate] = useReducer(x => x + 1, 0);


  const currentStep = useRef(0);
  const currentSubStep = useRef(0);

  const stepString = useMemo(() => t('step'), [t]);

  const openDialog = ({ manualOpen = true }) => {
    setOpen(true);
    currentStep.current = 0;
    currentSubStep.current = 0;
    setMaxSubSteps(steps[0].subStepsCount);

    if (manualOpen) {
      history.push({
        pathname: history.location.pathname,
        search: history.location.search,
        hash: `#${stepString}0-0`,
      });
    }
  };

  const closeDialog = ({ appCreated = false, manualClose = true }) => {
    setOpen(false);

    if (manualClose) {
      history.push({
        pathname: history.location.pathname,
        search: history.location.search,
      });
    }

    if (appCreated) { setAppIsCreated(true); }
  };

  const checkIfStepShouldBeSkipped = (step: number, subStep: number) => {
    const skipInfo = skippableSteps.find((skippableStep) => skippableStep.stepNumber === step);

    if (!skipInfo) {
      return false;
    }

    if (skipInfo.mode === 'STEP' || skipInfo.mode === 'SUB_STEP' && skipInfo.subStepNumber === subStep) {
      return skipInfo.skipOn(formData);
    }

    return false;
  };

  const checkAndUpdateFurthestStep = (step: number) => {
    if (step > furthestStep.step) { setFurthestStep({ step, subStep: 0 }); }
  };

  const checkAndUpdateFurthestSubStep = (step: number, subStep: number) => {
    if (step >= furthestStep.step && subStep > furthestStep.subStep) { setFurthestStep({ step, subStep }); }
  };

  const goToNextStep = (triggerHistory = true) => {
    setBackwards(false);

    analyticsAddEvent(OnlineFormEventType.NAVIGATION, OnlineFormEventSubType.FORWARD, { step: currentSubStep.current, subStep: currentSubStep.current });

    if (currentSubStep.current < maxSubSteps - 1) {
      // We want to go to next substep in the same step
      const skip = checkIfStepShouldBeSkipped(currentStep.current, currentSubStep.current + 1);

      currentSubStep.current += 1;
      checkAndUpdateFurthestSubStep(currentStep.current, currentSubStep.current);

      if (skip) {
        goToNextStep(triggerHistory);
      } else {
        // Don't push state, ex. when user goes back and forth using browser buttons
        if (triggerHistory) {
          history.push({
            pathname: history.location.pathname,
            search: history.location.search,
            hash: `#${stepString}${currentStep.current}-${currentSubStep.current}`,
          });
        }
      }
    } else {
      currentStep.current += 1;
      currentSubStep.current = 0;
      const skip = checkIfStepShouldBeSkipped(currentStep.current, 0);
      setMaxSubSteps(steps[currentStep.current].subStepsCount);
      setMaxSubSteps(steps[currentStep.current].subStepsCount);
      checkAndUpdateFurthestStep(currentStep.current);

      if (skip) {
        goToNextStep(triggerHistory);
      } else {
        // Don't push state, ex. when user goes back and forth using browser buttons
        if (triggerHistory) {
          history.push({
            pathname: history.location.pathname,
            search: history.location.search,
            hash: `#${stepString}${currentStep.current}-${0}`,
          });
        }
      }
    }

    analyticsUpdateFormSteps(currentStep.current, currentSubStep.current);
    forceUpdate();
  };

  const goToPreviousStep = (triggerHistory = true) => {
    setBackwards(true);

    analyticsAddEvent(OnlineFormEventType.NAVIGATION, OnlineFormEventSubType.BACKWARD, { step: currentSubStep, subStep: currentSubStep });

    if (currentSubStep.current > 0) {
      currentSubStep.current -= 1;
      const skip = checkIfStepShouldBeSkipped(currentStep.current, currentSubStep.current);

      if (skip) {
        goToPreviousStep(triggerHistory);
      } else {
        if (triggerHistory) { history.back(); }
      }
    } else {
      currentStep.current -= 1;
      currentSubStep.current = steps[currentStep.current].subStepsCount - 1;

      const skip = checkIfStepShouldBeSkipped(currentStep.current, currentSubStep.current);
      setMaxSubSteps(steps[currentStep.current].subStepsCount);

      if (skip) {
        goToPreviousStep(triggerHistory);
      } else {
        if (triggerHistory) { history.back(); }
      }
    }

    analyticsUpdateFormSteps(currentStep.current, currentSubStep.current);
    forceUpdate();
  };

  // In order to have the correct state when user goes back and forth in history, we need to re-listen on every state change
  useEffect(() => {
    // `unlisten` is a callback function that removes the listener
    const unlisten = history.listen(({ location, action }) => {
      // eslint-disable-next-line curly
      if (action === Action.Pop) {
        if (!location.hash || !location.hash.includes(stepString)) {
          closeDialog({ manualClose: false }); // If there is no step in pathname - we are back to start page
        } else {
          const [step, subStep] = location.hash.split(`#${stepString}`)[1].split('-').map(Number);

          if (step > currentStep.current || (subStep > currentSubStep.current && step === currentStep.current)) {
            // If user goes forward when in stepper
            goToNextStep(false);
          } else if (step < currentStep.current || (subStep < currentSubStep.current && step === currentStep.current)) {
            // If user goes back when in stepper
            goToPreviousStep(false);
          }

        }
      }
    });

    return () => unlisten();
  }, [appIsCreated, open, currentStep, currentSubStep, stepString]);

  const resetAppCreatedState = () => {
    setAppIsCreated(false);
  };

  const showNextStepButton = currentStep.current < furthestStep.step || currentSubStep.current < furthestStep.subStep;

  const value = useMemo(() => ({
    open,
    currentStep: currentStep.current,
    currentSubStep: currentSubStep.current,
    openDialog,
    closeDialog,
    setSteps,
    appIsCreated,
    goToNextStep,
    goToPreviousStep,
    showNextStepButton,
    backwards,
    setSkippableSteps,
    resetAppCreatedState,
  }), [open, currentStep?.current, currentSubStep?.current, openDialog, closeDialog, setSteps, appIsCreated, goToNextStep, goToPreviousStep, showNextStepButton, backwards, resetAppCreatedState]);

  return (
    <FormStepperDialogContext.Provider
      value={value}
    >
      {children}
    </FormStepperDialogContext.Provider>
  );
};

const useFormStepperDialogContext = () => useContext(FormStepperDialogContext);

export { FormStepperDialogContextProvider, useFormStepperDialogContext };
