import { FC, ReactNode } from 'react';

import { Grid } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import LivingCostField from 'src/components/fields/LivingSituation/LivingCostField';
import LivingSituationField from 'src/components/fields/LivingSituation/LivingSituationField';
import NextStepButton from 'src/components/Stepper/common/NextStepButton';
import { useBlancoSwedenFormContext } from 'src/contexts/BlancoSweden/BlancoSwedenFormContext';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';

interface BlancoSwedenLivingSituationSectionProps {
}

const BlancoSwedenLivingSituationSection: FC<BlancoSwedenLivingSituationSectionProps> = (props) => {
  const { } = props;

  const { formData, updateFormSection } = useBlancoSwedenFormContext();
  const { schemaFields } = useYupSchemaContext();

  const validationSchema = Yup.object().shape({
    livingSituation: schemaFields.livingSituation.livingSituation,
    livingCost: schemaFields.livingSituation.livingCost,
  });

  return (
    <Formik
      initialValues={formData.livingSituation}
      onSubmit={() => {}}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values }): ReactNode => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LivingSituationField name='livingSituation' />
          </Grid>
          <Grid item xs={12}>
            <LivingCostField name='livingCost' />
          </Grid>
          <Grid item xs={12}>
            <NextStepButton updateFormContextCallback={() => updateFormSection('livingSituation', values)} />
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};


export default BlancoSwedenLivingSituationSection;
