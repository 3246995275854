import { FC, ReactNode, useEffect, useMemo, useRef } from 'react';

import { Check } from '@mui/icons-material';
import { Button, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';

import DevTools from 'src/components/DevTools/DevTools';
import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';
import { useSettingsContext } from 'src/contexts/SettingsContext';
import { isNorway } from 'src/utils/country/isCountry';

interface BlancoSharedAppCreatedSectionProps {
}

const CustomListItem: FC<{ children: ReactNode }> = ({ children }) => (
  <ListItem
    sx={{
      alignItems: 'flex-start',
      py: 0,
      px: 0,
    }}
  >
    <ListItemIcon sx={{ pt: 0.75, minWidth: '32px' }}>
      <Check
        fontSize="small"
        color="primary"
      />
    </ListItemIcon>
    <ListItemText sx={{ '& a': { color: 'primary.main', whiteSpace: 'nowrap' } }}>
      {children}
    </ListItemText>
  </ListItem>
);

const BlancoSharedAppCreatedSection: FC<BlancoSharedAppCreatedSectionProps> = (props) => {
  const { } = props;

  const { t } = useTranslation();
  const isCountryNorway = isNorway();
  const { grFirst } = useSettingsContext();
  const { resetAppCreatedState } = useFormStepperDialogContext();

  const scrollRef = useRef(null);

  const redirectLink = useMemo(() => {
    let url = isCountryNorway ? 'https://minesider.zensum.no/gjeld' : 'https://minasidor.zensum.se/';

    if (isMobile) { url += '?login-redirect=true'; }

    return url;
  }, [isMobile, isCountryNorway]);


  // Scroll page into view due to modal scroll issue.
  // Two attempts - one when component is loaded and one after 250ms, to make sure transitions are completed
  useEffect(() => {
    scrollRef?.current?.scrollIntoView();

    setTimeout(() => {
      scrollRef?.current?.scrollIntoView();
    }, 250);
  }, []);

  return (
    <Grid
      container
      spacing={1}
      ref={scrollRef}
    >
      <Grid
        item
        xs={12}
      >
        <Typography sx={{ color: 'primary.main', fontSize: '1.5rem', fontWeight: '500', mb: 2 }}>
          {t('Thank you for your loan application!')}
        </Typography>
        <Typography sx={{ fontSize: '1.5rem', fontWeight: '500' }}>
          {t('What happens next?')}
        </Typography>
        <List sx={{ mb: 2 }}>
          <CustomListItem>
            <Trans>
              In just a few minutes, you&apos;ll get a link via SMS to log in with your BankID on
              {' '}
              <Link
                href='https://www.zensum.se/logga-in'
                target="_blank"
                rel="noreferrer"
              >
                My Pages
              </Link>.
            </Trans>
          </CustomListItem>
          <CustomListItem>
            <Trans>
              As lender offers arrive, you&apos;ll see them all in one place. The number and timing may vary, but a little patience will help you find the best option.
            </Trans>
          </CustomListItem>
        </List>
        <Typography>
          <Trans>
            <strong>Note!</strong>
            {' '}
            To make sure you get the best loan offers without affecting your credit score, avoid applying with other banks or brokers until you hear from Zensum.
          </Trans>
        </Typography>
        <Grid
          item
          xs={12}
          sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, pt: 2 }}
        >
          <Button
            component={Link}
            variant='contained'
            href={redirectLink}
            sx={{ width: '250px' }}
          >
            {t('Go to My Pages')}
          </Button>
          {grFirst && (
            <Button
              sx={{ fontSize: '0.75rem' }}
              onClick={() => { resetAppCreatedState(); }}
            >
              {t('Go back to your credits')}
            </Button>
          )}
        </Grid>
      </Grid>
      <DevTools />
    </Grid>
  );
};


export default BlancoSharedAppCreatedSection;
