enum BlancoSwedenEmploymentType {
  FullTime = 'fullTime',
  Hourly = 'hourly',
  SelfEmployed = 'selfEmployed',
  Trial = 'trial',
  ProjectStandin = 'projectStandin',
  Retired = 'retired',
  EarlyRetired = 'earlyRetired',
}

export default BlancoSwedenEmploymentType;
