import { Country, Product, ZCountry, ZProduct } from 'src/api/zrm';


export const zProductToProduct = (z: ZProduct): Product => {
  if (!z) return null;

  switch (z) {
    case ZProduct.MO:
      return Product.Mortgage;
    case ZProduct.UL:
      return Product.Blanco;
    case ZProduct.CC:
      return Product.CreditCard;
    default:
      return undefined;
  }
};

export const zCountryToCountry = (z: ZCountry): Country => {
  if (!z) return null;

  switch (z) {
    case ZCountry.SE:
      return Country.SE;
    case ZCountry.NO:
      return Country.NO;
    default:
      return undefined;
  }
};
