enum BlancoSwedenNewLoanPurpose {
  Consumption = 'consumption',
  Car = 'car',
  Health = 'health',
  Investment = 'investment',
  Travel = 'travel',
  HomeDownPayment = 'homeDownPayment',
  DebtCollelction = 'debtCollection',
  HomeRemodeling = 'homeRemodeling',
  Education = 'education',
  OnBehalft = 'onBehalf',
  InvestmentAbroad = 'investmentAbroad',
}

export default BlancoSwedenNewLoanPurpose;
