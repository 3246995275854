import { FC } from 'react';

import { Box, Slider, styled, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import CurrencyField from './CurrencyField';

// TODO: Reusable component like this
const ParentBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
});

interface FieldWithSliderProps {
  name: string;
  title: string;
  min: number;
  max: number;
}

const FieldWithSlider: FC<FieldWithSliderProps> = (props) => {
  const { name, title, min, max } = props;

  const { values, handleChange, setFieldValue } = useFormikContext<{ [key: string]: any; }>();


  return (
    <Box>
      <ParentBox>
        {/* TODO: this as variant to theme */}
        <Typography sx={{ fontWeight: '400', fontSize: '1.1rem' }}>
          {title}:
        </Typography>
        <CurrencyField
          size='small'
          value={values[name]}
          onBlur={(e) => {
            let value = parseInt(e.target.value, 10);

            if (isNaN(value)) value = min;

            if (value < min) value = min;
            if (value > max) value = max;

            setFieldValue(name, value);
          }}
          name={name}
          sx={{ width: '150px' }}
        />
      </ParentBox>
      <Slider 
        name={name}
        value={values[name]}
        onChange={handleChange}
        min={min}
        max={max}
        step={5000}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          variant="body2"
          sx={{ cursor: 'pointer' }}
        >
          {min} SEK
        </Typography>
        <Typography
          variant="body2"
          sx={{ cursor: 'pointer' }}
        >
          {max} SEK
        </Typography>
      </Box>
    </Box>
  );
};


export default FieldWithSlider;
