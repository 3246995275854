import { FC } from 'react';

import { ArrowForward } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useFormikContext } from 'formik';

import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';


interface ContinueWithFormButtonProps {
  updateFormContextCallback: () => void;
}

const ContinueWithFormButton: FC<ContinueWithFormButtonProps> = (props) => {
  const { updateFormContextCallback } = props;

  const { openDialog } = useFormStepperDialogContext();

  const { validateForm } = useFormikContext();

  const validateAndOpenDialog = async () => {
    const temp = await validateForm();

    console.log('temp', temp);

    if (Object.keys(temp).length === 0) {
      updateFormContextCallback();
      openDialog();
    }
  };

  return (
    <Button
      variant="contained" 
      onClick={() => validateAndOpenDialog()} 
      endIcon={<ArrowForward />}
      sx={{ width: '100%' }}
      size='large'
    >
      Next step
    </Button>
  );
};

export default ContinueWithFormButton;
