import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import FieldWithSlider from './base/FieldWithSlider';

interface LoanAmountFieldProps {
  name: string;
}

const min = 10000;
const max = 500000;

const LoanAmountField: FC<LoanAmountFieldProps> = (props) => {
  const { name } = props;

  const { t } = useTranslation();

  return (
    <FieldWithSlider name={name} title={t('Loan Amount')} min={min} max={max} />
  );
};


export default LoanAmountField;
