import { FC } from 'react';

import StepperDialog from 'src/components/dialogs/StepperDialog/StepperDialog';
import { BlancoSwedenFormContextProvider } from 'src/contexts/BlancoSweden/BlancoSwedenFormContext';

import BlancoSwedenFormStepper from './BlancoSwedenFormStepper';
import BlancoSwedenStartPageSection from './sections/BlancoSwedenStartPageSection';

interface BlancoSwedenStartPageProps {}

const BlancoSwedenStartPage: FC<BlancoSwedenStartPageProps> = (props) => {
  const { } = props;

  return (
    <BlancoSwedenFormContextProvider>
      <BlancoSwedenStartPageSection />
      <StepperDialog>
        <BlancoSwedenFormStepper />
      </StepperDialog>
    </BlancoSwedenFormContextProvider>
  );
};


export default BlancoSwedenStartPage;
