import { FC } from 'react';

import FieldWithSlider from './base/FieldWithSlider';

interface RefinanceAmountFieldProps {
  name: string;
}

const min = 0;
const max = 100000;

const RefinanceAmountField: FC<RefinanceAmountFieldProps> = (props) => {
  const { name } = props;

  return (
    <FieldWithSlider name={name} title='Amount to Refinance' min={min} max={max} />
  );
};


export default RefinanceAmountField;
