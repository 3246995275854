import { FC } from 'react';

import { Grid,  Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import StyledToggleButton from 'src/components/buttons/toggle/StyledToggleButton';
import StyledToggleButtonGroup from 'src/components/buttons/toggle/StyledToggleButtonGroup';


interface ChildrenFieldProps {
  name: string;
}

const ChildrenField: FC<ChildrenFieldProps> = (props) => {
  const { name } = props;

  const { values, setFieldValue, errors } = useFormikContext<{ [key: string]: any; }>();

  const error = errors[name] as string;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography>
          How many children do you have?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <StyledToggleButtonGroup
          value={values[name]}
          exclusive
          onChange={(e, value) => {
            if (value !== null)
              setFieldValue(name, value);
          }}
        >
          <Grid container spacing={2}>
            {[0, 1, 2, 3, 4].map((i) => (  
              <Grid item xs={2} key={`children=${i}`}>
                <StyledToggleButton value={`${i}`}>
                  {i}
                </StyledToggleButton>
              </Grid>
            ))}
            <Grid item xs={2}>
              <StyledToggleButton value={'5+'}>
                5+
              </StyledToggleButton>
            </Grid>
          </Grid>
        </StyledToggleButtonGroup>
      </Grid>
      {error && (
        <Grid item xs={12}>
          <Typography variant='caption' color="error">
            {error}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};


export default ChildrenField;
