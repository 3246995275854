import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useEffect } from 'react';

import { ApplicantRole, IncomeType, Product, QuickApplicant, QuickCreateBlancoApplication, OnlineFormEventType, OnlineFormEventSubType, MaritalStatus, Loan, Currency } from 'src/api/zrm';
import { useAnalyticsContext } from 'src/contexts/AnalyticsContext';
import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';
import { useSettingsContext } from 'src/contexts/SettingsContext';
import BlancoNorwayForm from 'src/types/BlancoNorway/form/BlancoNorwayForm';
import BlancoSwedenForm from 'src/types/BlancoSweden/form/BlancoSwedenForm';
import { isNorway, isSweden } from 'src/utils/country/isCountry';
import isEmploymentNameRequired from 'src/utils/employmentType/isEmployerNameRequired';
import camelCaseToText from 'src/utils/format/camelCaseToText';
import mapLoans from 'src/utils/loans/mapLoans';
import deepUpdate from 'src/utils/lodashLike/deepUpdate';

import { useBlancoApiFormContext } from './BlancoApiFormContext';
import { useSharedDataContext } from '../SharedDataContext';

type BlancoForm = BlancoSwedenForm | BlancoNorwayForm;

interface BlancoDataFormContextProps {
  formData: BlancoForm;
  updateFormSection: (key: string, values: any) => void;
  updateFormField: (path: string, value: any) => void;
  saveForm: () => void;
}

interface BlancoDataFormContextProviderProps {
  defaultData: BlancoForm;
  children: ReactNode;
}

const BlancoDataFormContext = createContext<BlancoDataFormContextProps>({
  formData: null,
  updateFormSection: () => { },
  saveForm: () => { },
  updateFormField: () => { },
});

interface DataFormType {
  formData: BlancoForm;
  setFormData: Dispatch<SetStateAction<BlancoForm>>;
}

const BlancoDataFormContextProvider: FC<BlancoDataFormContextProviderProps> = (props) => {
  const { defaultData, children } = props;

  const { country, formType } = useSettingsContext();
  const { formData, setFormData }: DataFormType = useSharedDataContext();

  const { closeDialog } = useFormStepperDialogContext();
  const { saveBlancoForm } = useBlancoApiFormContext();

  const { analyticsAddEvent } = useAnalyticsContext();

  const isCountryNorway = isNorway();
  const isCountrySweden = isSweden();

  const updateFormSection = (key: string, values: any) => {
    analyticsAddEvent(OnlineFormEventType.UPDATE, OnlineFormEventSubType.SUCCESS, { section_name: key });
    setFormData((prev) => {
      const temp = { ...prev };
      deepUpdate(temp, key, values);

      return temp;
    });
  };

  const updateFormField = (path: string, value: any) => {
    setFormData((prev) => {
      const temp = { ...prev };
      deepUpdate(temp, path, value);

      return temp;
    });
  };

  const saveForm = async () => {
    let loans: Loan[] = [];
    let employmentStartDate: string = null;
    let employmentEndDate: string = null;

    if (formData.income.employmentStartDate.year) { employmentStartDate = new Date(Date.UTC(formData.income.employmentStartDate.year, formData.income.employmentStartDate.month, 1)).toISOString(); }

    if (formData.income.employmentEndDate?.year) { employmentEndDate = new Date(Date.UTC(formData.income.employmentEndDate.year, formData.income.employmentEndDate.month, 1)).toISOString(); }


    let incomeAmount, yearlyIncomeAmount;

    if (isCountrySweden) {
      const data = formData as BlancoSwedenForm;

      incomeAmount = data.income.monthlyIncome;
    } else {
      const data = formData as BlancoNorwayForm;

      yearlyIncomeAmount = data.income.yearlyIncome;
    }

    const hasEmployer = isEmploymentNameRequired(formData.income.employmentType);

    const applicant: QuickApplicant = {
      role: ApplicantRole.MAIN_APPLICANT,
      pni: formData.pni.pni,
      email: formData.contact.email,
      phone_number: formData.contact.phone,
      currently_living_in_cost: formData.housing.livingCost,
      employer: {
        name: formData.income.employerName || '',
        type: formData.income.employmentType,
        start_date: employmentStartDate,
        end_date: employmentEndDate,
      },
      incomes: [
        {
          amount: !isCountryNorway ? incomeAmount : undefined,
          yearly_amount: isCountryNorway ? yearlyIncomeAmount : undefined,
          name: hasEmployer ? formData.income.employerName : `${camelCaseToText(formData.income.employmentType)} Income`,
          type: IncomeType.Employment,
          currency: isCountrySweden ? Currency.SEK : Currency.NOK,
        },
      ],
      housing_type: formData.housing.livingSituation,
      marital_status: formData.family.maritalStatus,
    };

    if (isCountryNorway) {
      const data = formData as BlancoNorwayForm;

      applicant.citizenship = data.pni.isNorwegianCitizen;
      loans = mapLoans(Object.values(data.loans));

      if ([MaritalStatus.Married, MaritalStatus.Partner].includes(data.family.maritalStatus)) {
        applicant.incomes.push({
          yearly_amount: data.income.spouseYearlyIncome,
          name: 'Partner Income',
          type: IncomeType.Partner,
          currency: Currency.NOK,
        });
      }

      if (data.income.hasYearlyRentIncome) {
        applicant.incomes.push({
          name: 'Rent Income',
          type: IncomeType.Rent,
          yearly_amount: data.income.yearlyRentIncome,
          currency: Currency.NOK,
        });
      }
    }

    const applicants = [applicant];

    if (formData.coApplicant.hasCoApplicant) {
      // Employment start and end date - Co Applicant

      let coEmploymentStartDate: string = null;
      let coEmploymentEndDate: string = null;

      if (formData.coApplicant.income.employmentStartDate.year) { coEmploymentStartDate = new Date(Date.UTC(formData.coApplicant.income.employmentStartDate.year, formData.coApplicant.income.employmentStartDate.month, 1)).toISOString(); }
      if (formData.coApplicant.income.employmentEndDate?.year) { coEmploymentEndDate = new Date(Date.UTC(formData.coApplicant.income.employmentEndDate.year, formData.coApplicant.income.employmentEndDate.month, 1)).toISOString(); }

      const coApplicantHasEmployer = isEmploymentNameRequired(formData.coApplicant.income.employmentType);

      /**
       * For Sweden we take `living cost, housing type & marital status` from co-applicant data, for Norway it's same as in main applicant
       */

      const coApplicant = {
        role: ApplicantRole.CO_APPLICANT,
        pni: formData.coApplicant.pni.pni,
        email: formData.coApplicant.contact.email,
        phone_number: formData.coApplicant.contact.phone,
        currently_living_in_cost: isCountrySweden ? formData.coApplicant.housing.livingCost : formData.housing.livingCost,
        employer: {
          name: formData.coApplicant.income.employerName || '',
          type: formData.coApplicant.income.employmentType,
          start_date: coEmploymentStartDate,
          end_date: coEmploymentEndDate,
        },
        incomes: [],
        housing_type: isCountrySweden ? formData.coApplicant.housing.livingSituation : formData.housing.livingSituation,
        marital_status: isCountrySweden ? formData.coApplicant.family.maritalStatus : formData.family.maritalStatus,
      } as QuickApplicant;

      if (isCountrySweden) {
        const data = formData as BlancoSwedenForm;

        coApplicant.incomes.push(
          {
            amount: data.coApplicant.income.monthlyIncome,
            name: coApplicantHasEmployer ? formData.coApplicant.income.employerName : `${camelCaseToText(data.coApplicant.income.employmentType)} Income`,
            type: IncomeType.Employment,
            currency: Currency.SEK,
          }
        );
      } else {
        const data = formData as BlancoNorwayForm;

        coApplicant.citizenship = data.coApplicant.pni.isNorwegianCitizen;
        coApplicant.incomes.push(
          {
            yearly_amount: data.income.spouseYearlyIncome,
            name: coApplicantHasEmployer ? formData.coApplicant.income.employerName : `${camelCaseToText(data.coApplicant.income.employmentType)} Income`,
            type: IncomeType.Employment,
            currency: Currency.NOK,
          }
        );
      }

      applicants.push(coApplicant);
    }

    // TODO: send ZID here
    const newLoanAmount = formData.startPage.loanAmount - +formData.startPage.refinanceAmount;
    const mappedData: QuickCreateBlancoApplication = {
      country: country,
      product: Product.Blanco,
      applicants,
      children: {
        childrenBelow18: +formData.family.children,
      },
      loans,
      new_app_checklist: [],
      new_app_options: [],
      source: null,
      consents: formData.startPage.consent ? {
        email_marketing: true,
        sms_marketing: true,
        customer_club_marketing: true,
      } : undefined,
      // if refinance is false or loanAmount is higher than refinanceAmount there is "space" for a new loan in the calculation
      new_loan: (!formData.startPage.refinance || Math.floor(newLoanAmount / 10_000) > 0) ? {
        desired_amount: !formData.startPage.refinance ? formData.startPage.loanAmount : newLoanAmount,
        loan_purpose: formData.startPage.newLoanPurpose,
      } : undefined,
      desired_amount: +formData.startPage.loanAmount,
      desired_payback_time: formData.startPage.repaymentTime * 12,
      form_type: formType,
    };

    if (isCountryNorway) {
      const data = formData as BlancoNorwayForm;

      if (data?.extraData?.isGrFirst) {
        mappedData.form_mode = 'gr_first';
      }
    }

    const success = await saveBlancoForm(mappedData);

    if (success) { closeDialog({ appCreated: true }); }
  };

  useEffect(() => {
    setFormData(defaultData);
  }, []);

  return (
    <BlancoDataFormContext.Provider
      value={{
        formData,
        updateFormSection,
        updateFormField,
        saveForm,
      }}
    >
      {children}
    </BlancoDataFormContext.Provider>
  );
};

const useBlancoDataFormContext = () => useContext(BlancoDataFormContext);

export { BlancoDataFormContextProvider, useBlancoDataFormContext };
