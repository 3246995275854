import { FC, ReactNode } from 'react';

import { Divider, Grid } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import FlexCenterBox from 'src/components/boxes/FlexCenterBox';
import ConsentCheckbox from 'src/components/fields/ConsentCheckbox';
import LoanAmountField from 'src/components/fields/LoanAmountField';
import RefinanceFormField from 'src/components/fields/RefinanceFormField';
import RepaymentTimeField from 'src/components/fields/RepaymentTimeField';
import ContinueWithFormButton from 'src/components/FormEntry/common/ContinueWithFormButton';
import { useBlancoSwedenFormContext } from 'src/contexts/BlancoSweden/BlancoSwedenFormContext';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';

interface BlancoSwedenStartPageSectionProps {
}

const BlancoSwedenStartPageSection: FC<BlancoSwedenStartPageSectionProps> = (props) => {
  const { } = props;

  const { formData, updateFormSection } = useBlancoSwedenFormContext();
  const { schemaFields } = useYupSchemaContext();

  const validationSchema = Yup.object().shape({
    loanAmount: schemaFields.startPage.loanAmount,
    repaymentTime: schemaFields.startPage.repaymentTime,
    refinance: schemaFields.startPage.refinance,
    refinanceAmount: schemaFields.startPage.refinanceAmount,
    newLoanPurpose: schemaFields.startPage.newLoanPurpose,
    consent: schemaFields.startPage.consent,
  });

  return (
    <Formik
      initialValues={formData.startPage}
      onSubmit={() => {}}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values }): ReactNode => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LoanAmountField name='loanAmount' />
          </Grid>
          <Grid item xs={12}>
          <RepaymentTimeField name='repaymentTime' />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Divider />
            <RefinanceFormField name='refinance' />
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ConsentCheckbox name='consent' />
          </Grid>
          <Grid item xs={12}>
            <FlexCenterBox>
              <ContinueWithFormButton updateFormContextCallback={() => updateFormSection('startPage', values)} />
            </FlexCenterBox>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};


export default BlancoSwedenStartPageSection;
