import { FC, ReactNode } from 'react';

import { Grid } from '@mui/material';
import { Formik } from 'formik';

import FlexCenterBox from 'src/components/boxes/FlexCenterBox';
import ContinueWithFormButton from 'src/components/FormEntry/common/ContinueWithFormButton';

interface BlancoNorwayStartPageSectionProps {
}

const BlancoNorwayStartPageSection: FC<BlancoNorwayStartPageSectionProps> = (props) => {
  const { } = props;

  return (
    <Formik
      initialValues={{}}
      onSubmit={() => {}}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ }): ReactNode => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            Blanco Norway
          </Grid>
          <Grid item xs={12}>
            <FlexCenterBox>
              <ContinueWithFormButton updateFormContextCallback={() => {}} />
            </FlexCenterBox>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};


export default BlancoNorwayStartPageSection;
