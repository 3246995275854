import { createContext, FC, ReactNode, useContext, useState } from 'react';

import blancoSwedenFormDefaultData from 'src/defaults/BlancoSweden/BlancoSwedenFormDefaultData';
import BlancoSwedenForm, { BlancoSwedenFormSection } from 'src/types/BlancoSweden/form/BlancoSwedenForm';

interface BlancoSwedenFormContextProps {
  formData: BlancoSwedenForm;
  updateFormSection: (key: keyof BlancoSwedenForm, values: BlancoSwedenFormSection) => void;
}

interface BlancoSwedenFormContextProviderProps {
  children: ReactNode;
}

const BlancoSwedenFormContext = createContext<BlancoSwedenFormContextProps>({
  formData: blancoSwedenFormDefaultData,
  updateFormSection: () => {},
});

const BlancoSwedenFormContextProvider: FC<BlancoSwedenFormContextProviderProps> = (props) => {
  const { children } = props;

  const [formData, setFormData] = useState<BlancoSwedenForm>(blancoSwedenFormDefaultData);

  const updateFormSection = (key: keyof BlancoSwedenForm, values: BlancoSwedenFormSection ) => {
    setFormData((prev) => ({ ...prev, [key]: values }));
  };
  
  return (
    <BlancoSwedenFormContext.Provider
      value={{
        formData,
        updateFormSection,
      }}
    >
      {children}
    </BlancoSwedenFormContext.Provider>
  );
};

const useBlancoSwedenFormContext = () => useContext(BlancoSwedenFormContext);

export { BlancoSwedenFormContextProvider, useBlancoSwedenFormContext };
