import { FC } from 'react';

import { Box, Step, StepContent, StepLabel, Stepper, styled } from '@mui/material';

import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';
import useIsMobile from 'src/hooks/mediaQuery/useIsMobile';
import SingleStep from 'src/types/Stepper/SingleStep';

import FlexColumnCenterBox from '../boxes/FlexColumnCenterBox';

const StyledStepper = styled(Stepper)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    boxShadow: '0 8px 8px -8px gray',
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

interface FormStepperProps {
  steps: SingleStep[];
}

const FormStepper: FC<FormStepperProps> = (props) => {
  const { steps } = props;

  const isMobile = useIsMobile();

  const { currentStep } = useFormStepperDialogContext();

  return (
    <FlexColumnCenterBox>
      <StyledStepper 
        activeStep={currentStep} 
        orientation={isMobile ? 'vertical' : 'horizontal'} 
        alternativeLabel={!isMobile}
      >
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            {isMobile && (
              <StepContent>
                {step.component}
              </StepContent>
            )}
          </Step>
        ))}
      </StyledStepper>
      {!isMobile && (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ p: 3 }}>
            {steps[currentStep].component}
          </Box>
        </Box>
      )}
    </FlexColumnCenterBox>
  );
};


export default FormStepper;
