import { FC, useState } from 'react';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Card, Collapse, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Country } from 'src/api/zrm';
import ExternallyVerifiedCreditExtra from 'src/types/GR/ExternallyVerifiedCreditExtra';
import camelCaseToText from 'src/utils/format/camelCaseToText';
import formatCurrency from 'src/utils/format/formatCurrency';
import formatPercent from 'src/utils/format/formatPercent';

import GrCreditBoxMobileRow from './GrCreditBoxMobileRow';

interface GrCreditBoxMobileProps {
  credit: ExternallyVerifiedCreditExtra;
}

const GrCreditBoxMobile: FC<GrCreditBoxMobileProps> = (props) => {
  const { credit } = props;

  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  return (
    <Card
      sx={{
        mx: '-24px',
        borderRadius: 0,
        px: 1,
      }}
      onClick={() => setExpanded(p => !p)}
    >
      <Grid
        container
        sx={{ pt: 2 }}
      >
        <GrCreditBoxMobileRow
          title={t('Creditor')}
          value={credit.financialInstitutionName}
          valueSx={{ color: 'primary.main' }}
        />
        <GrCreditBoxMobileRow
          title={t('Loan Type')}
          value={t(camelCaseToText(credit.gjeldsregisteret_type))}
        />
        <GrCreditBoxMobileRow
          title={t('Amount')}
          value={formatCurrency(credit.total_debt, Country.NO, 0)}
        />
        <GrCreditBoxMobileRow
          title={t('Interest')}
          value={formatPercent(credit.nominalInterestRate, Country.NO, 2)}
        />
        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit
          sx={{ width: '100%' }}
        >
          <GrCreditBoxMobileRow
            title={t('Non-interest Bearing Amount')}
            value={formatCurrency(credit.nonInterestBearingBalance, Country.NO, 0)}
          />
          <GrCreditBoxMobileRow
            title={t('Interest Bearing Amount')}
            value={formatCurrency(credit.interestBearingBalance, Country.NO, 0)}
          />
          <GrCreditBoxMobileRow
            title={t('Fees')}
            value={formatCurrency(credit.installmentCharges, Country.NO, 0)}
          />
          <GrCreditBoxMobileRow
            title={t('With co-applicant')}
            value={credit.hasCoBorrower ? t('Yes') : t('No')}
          />
          <GrCreditBoxMobileRow
            title={t('Monthly Payment')}
            value={formatCurrency(100, Country.NO, 0)}
          />
        </Collapse>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 2, py: 1, '& .MuiSvgIcon-root, & .MuiTypography-root': { fontWeight: 'bold' } }}
        >
          <Typography>
            {expanded ? t('Show less') : t('Show more')}
          </Typography>
          {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Grid>
      </Grid>
    </Card >
  );
};


export default GrCreditBoxMobile;
