import { FC, useState } from 'react';

import { Close } from '@mui/icons-material';
import { Box, Button, Checkbox, Container, Drawer, FormControlLabel, IconButton, Link, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { useMarketingConsentContext } from 'src/contexts/consents/MarketingConsentContext';

import FlexCenterBox from '../boxes/FlexCenterBox';


const MarketingConsentBox: FC = () => {
  const { showConstentBox, closeConsent } = useMarketingConsentContext();

  const { t } = useTranslation();

  const [consentChecked, setConsentChecked] = useState(false);

  return (
    <Drawer
      sx={{
        width: '100%',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '100%',
        },
      }}
      variant="temporary"
      anchor="bottom"
      open={showConstentBox}
      onClose={() => closeConsent(consentChecked)}
      transitionDuration={750}
    >
      <Container
        maxWidth="lg"
        sx={{ p: 2, pt: 1 }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
          <IconButton
            sx={{ p: 0 }}
            onClick={() => closeConsent(consentChecked)}
          >
            <Close />
          </IconButton>
        </Box>
        <Typography
          sx={{
            fontSize: '0.9rem',
          }}
        >
          <Trans>
            <Typography sx={{ fontSize: 'inherit' }}>
              Do you consent to Zensum sending loan and financial information, offers, tips or similar marketing via email and sms?
              You can easily remove your consent via the link in each message.
              Consent is voluntary and not a requirement to use our services.
            </Typography>
            <Link
              href="https://gdpr-info.eu/"
              target="_blank"
            >
              About GDPR on the EUs website.
            </Link>
          </Trans>
        </Typography>
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <FormControlLabel
            checked={consentChecked}
            color="primary"
            control={<Checkbox />}
            onChange={() => setConsentChecked((p) => !p)}
            label={t('Yes, I consent')}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 36 }, '& .MuiFormControlLabel-label': { marginLeft: '-8px' } }}
          />
          <img
            src="/static/gdpr_eu.png"
            height="70"
            width="auto"
            alt="GDPR EU"
            loading="lazy"
          />
        </Box>
        <FlexCenterBox sx={{ pt: 2 }}>
          <Button
            variant="outlined"
            color="success"
            sx={{
              width: '100%',
              maxWidth: '400px',
              borderWidth: '3px',
              borderColor: 'primary.main',
              color: 'primary.main',
              '&:hover': {
                borderWidth: '3px',
              },
            }}
            onClick={() => closeConsent(consentChecked)}

          >
            {t('View my details')}
          </Button>
        </FlexCenterBox>
      </Container>

    </Drawer>
  );
};

export default MarketingConsentBox;
