import { FC } from 'react';

import { Grid, Typography } from '@mui/material';

import FinishButton from 'src/components/Stepper/common/FinishButton';
import { useBlancoSwedenFormContext } from 'src/contexts/BlancoSweden/BlancoSwedenFormContext';

interface BlancoSwedenSummarySectionProps {
}

const BlancoSwedenSummarySection: FC<BlancoSwedenSummarySectionProps> = (props) => {
  const { } = props;

  const { formData } = useBlancoSwedenFormContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>Your informations</Typography>
      </Grid>
      <Grid item xs={12}>
        <pre>
          {JSON.stringify(formData, null, 2)}
        </pre>
      </Grid>
      <Grid item xs={12}>
        <FinishButton />
      </Grid>
    </Grid>
  );
};


export default BlancoSwedenSummarySection;
