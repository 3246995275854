import { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { v4 as uuid } from 'uuid';

import { ConsentsPayload } from 'src/api/zrm';
import parseDate from 'src/utils/lodashLike/parseDate';

import { useApi } from '../APIContext';
import { useCustomerDataContext } from '../auth/CustomerDataContext';

export interface TestConsent {
  hasConsent: boolean;
  lastSeen: Date;
}

export interface MarketingConsentContextProps {
  showConstentBox: boolean;
  closeConsent: (consentChecked: boolean) => void;
}

const MarketingConsentContext = createContext<MarketingConsentContextProps>({ showConstentBox: false, closeConsent: () => { } });

const MarketingConsentContextProvider: FC<{ children: ReactNode }> = (props) => {
  const { children } = props;
  const { api } = useApi();
  const { customerData } = useCustomerDataContext();

  const [consentInfo, setConsentInfo] = useState<TestConsent>({ hasConsent: true, lastSeen: new Date() });

  const saveConsent = useMemo(() => async (consentChecked: boolean) => {
    const requestId = uuid();

    const payload: ConsentsPayload = consentChecked ? ({ consents: { customer_club_marketing: true, email_marketing: true, sms_marketing: true } }) : ({ dismissed_consents_popup: true });

    try {
      await api.myPages.upsertMarketingConsentMyPagesMarketingConsentsPost(
        payload, { headers: { 'X-Request-ID': requestId }, cancelToken: requestId },
      );
    } catch (error) {

    }
  }, []);

  const closeConsent = useMemo(() => (consentChecked: boolean) => {
    setConsentInfo({ hasConsent: consentChecked, lastSeen: new Date() });

    saveConsent(consentChecked);
  }, [saveConsent]);

  useEffect(() => {
    if (customerData) {
      setConsentInfo({
        hasConsent: customerData?.consents && customerData.consents.customer_club_marketing && customerData.consents.email_marketing && customerData.consents.sms_marketing,
        lastSeen: customerData?.consents_popup_dismissed_timestamp ? parseDate(customerData?.consents_popup_dismissed_timestamp).toDate() : null,
      });
    }
  }, [customerData]);

  const showConstentBox = useMemo(() => {
    if (consentInfo.hasConsent) { return false; }

    if (consentInfo.lastSeen && parseDate().diff(parseDate(consentInfo.lastSeen), 'days') < 30) { return false; }

    return true;
  }, [consentInfo]);

  return (
    <MarketingConsentContext.Provider
      value={{
        showConstentBox, closeConsent,
      }}
    >
      {children}
    </MarketingConsentContext.Provider>
  );
};

const useMarketingConsentContext = () => useContext(MarketingConsentContext);

export { MarketingConsentContextProvider, useMarketingConsentContext };
