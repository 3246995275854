import { createContext, FC, ReactNode, useContext } from 'react';

import * as Yup from 'yup';

import { useValidatorContext } from './ValidatorContext';


interface YupSchemaContextProps {
  schemaFields: Record<string, any>;
}

const YupSchemaContext = createContext<YupSchemaContextProps>({
  schemaFields: {},
});

export const YupSchemaContextProvider: FC<{ children: ReactNode }> = (props) => {
  const { children } = props;

  const { externalValidator } = useValidatorContext();

  const schemaFields = {
    startPage: {
      consent: Yup.boolean().oneOf([true], 'Field must be checked').required('Required'),
      loanAmount: Yup.number().required('Required'),
      newLoanPurpose: Yup.string().when('refinance', {
        is: false,
        then: (schema) => schema.required('Required'),
      }),
      refinanceAmount: Yup.number(),
      refinance: Yup.boolean(),
      repaymentTime: Yup.number().required('Required'),
    },
    livingSituation: {
      livingSituation: Yup.string().required('Required'),
      livingCost: Yup.number().required('Required').max(10000, 'Max 10000').min(0, 'Min 0'),
    },
    family: {
      maritalStatus: Yup.string().required('Required'),
      children: Yup.string().required('Required'),
    },
    income: {
      employerName: Yup.string().required('Required'),
      yearlyIncome: Yup.number().required('Required'),
      employmentType: Yup.string().required('Required'),
    },
    contact: {
      email: Yup.string()
        .email()
        .test(
          '',
          'Not a valid email',
          (value) => value && externalValidator({ email: value }),
        )
        .required('Email is required'),
      phone: Yup.string()
        .test(
          '',
          'Not a valid phone number',
          (value) => value && externalValidator({ phone_number: value }),
        )
        .required('Phone number is required'),
      pni: Yup.string()
        .test(
          '',
          'Not a valid PNI',
          (value) => value && externalValidator({ pni: value }),
        )
        .required('PNI is required'),
    },
  };


  return (
    <YupSchemaContext.Provider value={{
      schemaFields,
    }}
    >
      {children}
    </YupSchemaContext.Provider>
  );
};

export const useYupSchemaContext = (): YupSchemaContextProps => useContext(YupSchemaContext);
