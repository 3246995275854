import { FC, ReactNode } from 'react';

import { Grid } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import ChildrenField from 'src/components/fields/Family/ChildrenField';
import MaritalStatusField from 'src/components/fields/Family/MaritalStatusField';
import NextStepButton from 'src/components/Stepper/common/NextStepButton';
import { useBlancoSwedenFormContext } from 'src/contexts/BlancoSweden/BlancoSwedenFormContext';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';

interface BlancoSwedenFamilySectionProps {
}

const BlancoSwedenFamilySection: FC<BlancoSwedenFamilySectionProps> = (props) => {
  const { } = props;

  const { formData, updateFormSection } = useBlancoSwedenFormContext();
  const { schemaFields } = useYupSchemaContext();

  const validationSchema = Yup.object().shape({
    maritalStatus: schemaFields.family.maritalStatus,
    children: schemaFields.family.children,
  });

  return (
    <Formik
      initialValues={formData.family}
      onSubmit={() => {}}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values }): ReactNode => (
      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <MaritalStatusField name='maritalStatus' />
        </Grid>
        <Grid item xs={12}>
          <ChildrenField name='children' />
        </Grid>
        <Grid item xs={12}>
          <NextStepButton updateFormContextCallback={() => updateFormSection('family', values)} />
        </Grid>
      </Grid>
      )}  
    </Formik>
  );
};


export default BlancoSwedenFamilySection;
