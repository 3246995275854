import { FC } from 'react';

import { TextField, TextFieldProps } from '@mui/material';
import { useFormikContext } from 'formik';
import { NumericFormat } from 'react-number-format';

const CurrencyField: FC<TextFieldProps> = (props) => {
  const { name } = props;

  const { values, setFieldValue } = useFormikContext<{ [key: string]: any; }>();

  return (
    <NumericFormat
      thousandSeparator=" "
      decimalSeparator=","
      allowedDecimalSeparators={[',', '.']}
      customInput={TextField}
      InputProps={{ endAdornment: 'SEK' }}
      value={values[name]}
      onValueChange={(v) => {
        setFieldValue(name, v.floatValue);
      }}
      onBlur={(e) => props.onBlur && props.onBlur({ ...e, target: { ...e.target, value: values[name] } })}
    />
  );
};

export default CurrencyField;
