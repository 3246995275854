import { FC } from 'react';

import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';

interface YearFieldProps {
  name: string;
}

const YearField: FC<YearFieldProps> = (props) => {
  const { name } = props;

  const { values, handleChange } = useFormikContext<{ [key: string]: any; }>();

  return (
    <TextField
      value={values[name]}
      onChange={handleChange}
      name={name}
      type='number'
      label='Year'
      fullWidth
    />
  );
};


export default YearField;
