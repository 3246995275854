import { FC } from 'react';

import { Grid,  Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import StyledToggleButton from 'src/components/buttons/toggle/StyledToggleButton';
import StyledToggleButtonGroup from 'src/components/buttons/toggle/StyledToggleButtonGroup';


interface LivingSituationFieldProps {
  name: string;
}

const LivingSituationField: FC<LivingSituationFieldProps> = (props) => {
  const { name } = props;

  const { values, setFieldValue, errors } = useFormikContext<{ [key: string]: any; }>();

  const error = errors[name] as string;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography>
          Where do you live?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <StyledToggleButtonGroup
          value={values[name]}
          exclusive
          onChange={(e, value) => {
            if (value !== null) setFieldValue(name, value);
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <StyledToggleButton value="Own house">
                Own house
              </StyledToggleButton>
            </Grid>
            <Grid item xs={3}>
              <StyledToggleButton value="Condominium">
                Condominium
              </StyledToggleButton>
            </Grid>
            <Grid item xs={3}>
              <StyledToggleButton value="Own apartment">
                Own apartment
              </StyledToggleButton>
            </Grid>
            <Grid item xs={3}>
              <StyledToggleButton value="Rental">
                Rental
              </StyledToggleButton>
            </Grid>
          </Grid>
        </StyledToggleButtonGroup>
      </Grid>
      {error && (
        <Grid item xs={12}>
          <Typography variant='caption' color="error">
            {error}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};


export default LivingSituationField;
