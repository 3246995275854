import { FC, useEffect, useMemo, useState } from 'react';

import { Box, Button, CircularProgress, Container, Grid, Link, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import { ApplicantNO, Country } from 'src/api/zrm';
import FlexCenterBox from 'src/components/boxes/FlexCenterBox';
import MarketingConsentBox from 'src/components/Consents/MarketingConsentBox';
import GrCreditDesktopTable from 'src/components/gr/TableData/GrCreditDesktopTable';
import GrCreditMobileList from 'src/components/gr/TableData/GrCreditMobileList';
import { useApi } from 'src/contexts/APIContext';
import { useCustomerDataContext } from 'src/contexts/auth/CustomerDataContext';
import { BlancoApiFormContextProvider } from 'src/contexts/blanco/BlancoApiFormContext';
import { BlancoDataFormContextProvider } from 'src/contexts/blanco/BlancoDataFormContext';
import { useFormStepperDialogContext } from 'src/contexts/FormStepperDialogContext';
import BlancoNorwayFormDefaultData from 'src/defaults/BlancoNorway/BlancoNorwayFormDefaultData';
import BlancoNorwayFormStepperDialog from 'src/forms/BlancoNorway/BlancoNorwayFormStepperDialog';
import useIsMobile from 'src/hooks/mediaQuery/useIsMobile';
import BlancoNorwayForm from 'src/types/BlancoNorway/form/BlancoNorwayForm';
import ExternallyVerifiedCreditExtra from 'src/types/GR/ExternallyVerifiedCreditExtra';
import formatCurrency from 'src/utils/format/formatCurrency';

interface GrFirstCreditsProps { }

const GrFirstCredits: FC<GrFirstCreditsProps> = (props) => {
  const { } = props;

  const { t } = useTranslation();
  const { api } = useApi();
  const { customerData, setCustomerData } = useCustomerDataContext();
  const isMobile = useIsMobile();

  const { openDialog } = useFormStepperDialogContext();

  const [loading, setLoading] = useState(false);

  const fetchGRData = useMemo(() => async (requestId: string) => {
    setLoading(true);

    try {
      const resp = await api.myPages.debtRegisterWithConsentMyPagesDebtRegisterWithConsentPost(
        { cancelToken: requestId, headers: { 'X-Request-ID': requestId } },
      );

      setCustomerData(resp.data as ApplicantNO);
    } catch (e) {

    }

    setLoading(false);
  }, [api]);

  const credits = useMemo(() => (customerData as ApplicantNO)?.externally_verified_credits.map(c => ({ ...c, uuid: v4() })) as ExternallyVerifiedCreditExtra[], [customerData]);
  const totalAmount = useMemo(() => credits?.reduce((prev, curr) => prev + curr.total_debt, 0) || 0, [credits]);

  const defaultData: BlancoNorwayForm = {
    ...BlancoNorwayFormDefaultData,
    startPage: {
      ...BlancoNorwayFormDefaultData.startPage,
      loanAmount: +totalAmount.toFixed(0),
      refinanceAmount: +totalAmount.toFixed(0),
      refinance: true,
    },
    pni: {
      pni: (customerData as ApplicantNO)?.pni,
    },
    extraData: {
      isGrFirst: true,
    },
  };

  useEffect(() => {
    const requestId = v4();

    fetchGRData(requestId);

    return () => { api.abortRequest(requestId); };
  }, [fetchGRData]);


  if (loading) {
    return (
      <>
        <FlexCenterBox sx={{ mb: 2 }}>
          <Typography sx={{ fontStyle: 'italic' }}>
            {t('Please wait until your data is loaded...')}
          </Typography>
        </FlexCenterBox>
        <FlexCenterBox>
          <CircularProgress
            color="success"
            size={60}
          />
        </FlexCenterBox>
      </>
    );
  }

  return (
    <Container maxWidth="xl">
      <Grid
        container
        spacing={5}
      >
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          sx={{ '& .MuiTypography-root': { fontWeight: 'bold' } }}
        >
          <Typography sx={{ fontSize: '2rem', mb: 1 }}>
            {t('My Credits')}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: '1.25rem' }}>
              {t('Total Amount')}
            </Typography>
            <Typography sx={{ fontSize: '1.25rem' }}>
              {formatCurrency(totalAmount, Country.NO, 0)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography>
              {t('Number of Credits')}
            </Typography>
            <Typography>
              {credits?.length || 0}
            </Typography>
          </Box>
        </Grid>
        {!credits?.length && (
          <Grid
            item
            xs={12}
          >
            <Typography>{t('You don\'t have any credits.')}</Typography>
          </Grid>
        )}
        {!!credits?.length && (
          <>
            <Grid
              item
              xs={12}
            >
              <FlexCenterBox>
                <Button
                  variant='contained'
                  sx={{ fontWeight: 'bold', width: isMobile ? '100%' : '400px' }}
                  onClick={(() => openDialog({}))}
                >
                  {t('Compare your loans')}
                </Button>
              </FlexCenterBox>
            </Grid>
            <Grid
              item
              xs={12}
            >
              {isMobile && (
                <GrCreditMobileList credits={credits} />
              )}
              {!isMobile && (
                <GrCreditDesktopTable credits={credits} />
              )}
            </Grid>
            <Grid
              item
              xs={12}
            >
              <FlexCenterBox sx={{ flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                  {t('Too many and/or expensive loans?')}
                </Typography>
                <Button
                  variant='contained'
                  sx={{ fontWeight: 'bold', width: isMobile ? '100%' : '400px' }}
                  onClick={(() => openDialog({}))}
                >
                  {t('Compare and consolidate loans')}
                </Button>
              </FlexCenterBox>
            </Grid>
          </>
        )}
        <Grid
          item
          xs={12}
          sx={{ '& .MuiTypography-root': { mb: 1, fontSize: '0.9rem' } }}
        >
          <Typography>
            <Trans>
              The debt information is provided by Gjeldsregisteret AS. An overview of financial companies that supply debt information to Gjeldsregisteret AS can be found at
              {' '}
              <Link
                href='http://gjeldsregisteret.com/'
                target='_blank'
              >
                gjeldsregisteret.com
              </Link>
              .
            </Trans>
          </Typography>
          <Typography>
            <Trans>
              You easily manage your consent at the
              {' '}
              <Link
                href='https://www.gjeldsregisteret.com/pages/innsyn-i-egne-opplysninger-og-administrasjon-av-samtykke'
                target='_blank'
              >
                Gjeldsregisteret
              </Link>
              .
            </Trans>
          </Typography>
        </Grid>
      </Grid>
      <MarketingConsentBox />
      <BlancoApiFormContextProvider>
        <BlancoDataFormContextProvider defaultData={defaultData}>
          <BlancoNorwayFormStepperDialog />
        </BlancoDataFormContextProvider>
      </BlancoApiFormContextProvider>
    </Container>
  );
};


export default GrFirstCredits;
