import { FC } from 'react';

import { Box } from '@mui/material';

const ZensumLogo: FC = () => {
  return (
    <Box>
      ZENSUM.se
    </Box>
  );
};


export default ZensumLogo;
