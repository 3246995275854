import { StrictMode } from 'react';

import ReactDOM from 'react-dom/client';

import App from './App';
import { APIProvider } from './contexts/APIContext';
import { SettingsProvider } from './contexts/SettingsContext';
import ThemeControlContextProvider from './contexts/ThemeControlContext';
import { ValidatorContextProvider } from './contexts/ValidatorContext';
import { YupSchemaContextProvider } from './contexts/YupSchemaContext';
import OnlineFormType from './types/OnlineFormType';


import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const ROOT_ELEMENT_ID = process.env.REACT_APP_ROOT_ELEMENT_ID || 'online-forms';

const root = ReactDOM.createRoot(
  document.getElementById(ROOT_ELEMENT_ID) as HTMLElement
);

const formType = document.getElementById(ROOT_ELEMENT_ID)?.getAttribute('data-formType') || OnlineFormType.BlancoSweden;

root.render(
  <StrictMode>
    <APIProvider>
      <SettingsProvider onlineFormType={formType as OnlineFormType}>
        <ValidatorContextProvider>
          <YupSchemaContextProvider>
            <ThemeControlContextProvider>
              <App onlineFormType={formType as OnlineFormType} />
            </ThemeControlContextProvider>
          </YupSchemaContextProvider>
        </ValidatorContextProvider>
      </SettingsProvider>
    </APIProvider>
  </StrictMode>
);
