import { FC, ReactNode } from 'react';

import { Grid } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import EmployerNameField from 'src/components/fields/Income/EmployerNameField';
import EmploymentDateField from 'src/components/fields/Income/EmploymentDateField';
import EmploymentEndDateField from 'src/components/fields/Income/EmploymentEndDateField';
import EmploymentTypeField from 'src/components/fields/Income/EmploymentTypeField';
import RetirementDateField from 'src/components/fields/Income/RetirementDateField';
import YearlyIncomeField from 'src/components/fields/Income/YearlyIncomeField';
import NextStepButton from 'src/components/Stepper/common/NextStepButton';
import { useBlancoSwedenFormContext } from 'src/contexts/BlancoSweden/BlancoSwedenFormContext';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';
import BlancoSwedenEmploymentType from 'src/types/BlancoSweden/BlancoSwedenEmploymentType';

interface BlancoSwedenIncomeSectionProps {
}

const BlancoSwedenIncomeSection: FC<BlancoSwedenIncomeSectionProps> = (props) => {
  const { } = props;

  const { formData, updateFormSection } = useBlancoSwedenFormContext();
  const { schemaFields } = useYupSchemaContext();

  const validationSchema = Yup.object().shape({
    employmentType: schemaFields.income.employmentType,
    yearlyIncome: schemaFields.income.yearlyIncome,
    employerName: schemaFields.income.employerName,
  });

  return (
    <Formik
      initialValues={formData.income}
      onSubmit={() => {}}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values }): ReactNode => {

        const showEmploymentDate = [
          BlancoSwedenEmploymentType.FullTime, BlancoSwedenEmploymentType.Hourly, BlancoSwedenEmploymentType.SelfEmployed,
          BlancoSwedenEmploymentType.Trial, BlancoSwedenEmploymentType.ProjectStandin,
        ].includes(values.employmentType);
  
        const showEmploymentEndDate = [BlancoSwedenEmploymentType.ProjectStandin].includes(values.employmentType);
  
        const showRetirementDate = [BlancoSwedenEmploymentType.Retired, BlancoSwedenEmploymentType.EarlyRetired].includes(values.employmentType);

        return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EmploymentTypeField name='employmentType' />
          </Grid>
          <Grid item xs={12}>
            <YearlyIncomeField name='yearlyIncome' />
          </Grid>
          {showEmploymentDate && (
            <Grid item xs={12}>
              <EmploymentDateField />
            </Grid>
          )}
          {showEmploymentEndDate && (
            <Grid item xs={12}>
              <EmploymentEndDateField />
            </Grid> 
          )}
          {showRetirementDate && (
            <Grid item xs={12}>
              <RetirementDateField />
            </Grid>
          )}
          <Grid item xs={12}>
            <EmployerNameField name='employerName' />
          </Grid>
          <Grid item xs={12}>
            <NextStepButton updateFormContextCallback={() => updateFormSection('income', values)} />
          </Grid>
        </Grid>
        );
      }}
    </Formik>
  );
};


export default BlancoSwedenIncomeSection;
