import { FC, ReactNode } from 'react';

import { Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

import EmailField from 'src/components/fields/Contact/EmailField';
import PhoneField from 'src/components/fields/Contact/PhoneField';
import PNIField from 'src/components/fields/Contact/PNIField';
import NextStepButton from 'src/components/Stepper/common/NextStepButton';
import { useBlancoSwedenFormContext } from 'src/contexts/BlancoSweden/BlancoSwedenFormContext';
import { useYupSchemaContext } from 'src/contexts/YupSchemaContext';

interface BlancoSwedenContactSectionProps {
}

const BlancoSwedenContactSection: FC<BlancoSwedenContactSectionProps> = (props) => {
  const { } = props;

  const { formData, updateFormSection } = useBlancoSwedenFormContext();
  const { schemaFields } = useYupSchemaContext();

  const validationSchema = Yup.object().shape({
    email: schemaFields.contact.email,
    phone: schemaFields.contact.phone,
    pni: schemaFields.contact.pni,
  });

  return (
    <Formik
      initialValues={formData.contact}
      onSubmit={() => {}}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values }): ReactNode => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Provide your contact information</Typography>
          </Grid>
          <Grid item xs={12}>
            <EmailField name='email' />
          </Grid>
          <Grid item xs={12}>
            <PhoneField name='phone' />
          </Grid>
          <Grid item xs={12}>
            <PNIField name='pni' />
          </Grid>
          <Grid item xs={12}>
            <NextStepButton updateFormContextCallback={() => updateFormSection('contact', values)} />
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};


export default BlancoSwedenContactSection;
