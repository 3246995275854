
import { FC } from 'react';

import FormSelector from './components/FormEntry/FormSelector';
import { FormSelectorContextProvider } from './contexts/FormSelectorContext';
import { FormStepperDialogContextProvider } from './contexts/FormStepperDialogContext';
import OnlineFormType from './types/OnlineFormType';


interface AppProps {
  onlineFormType: OnlineFormType;
}

const App: FC<AppProps> = (props) => {
  const { onlineFormType } = props;

  return (
    <FormSelectorContextProvider onlineFormType={onlineFormType}>
      <FormStepperDialogContextProvider>
        <FormSelector />
      </FormStepperDialogContextProvider>
    </FormSelectorContextProvider>
  );
};

export default App;
