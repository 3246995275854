import { FC } from 'react';

import StepperDialog from 'src/components/dialogs/StepperDialog/StepperDialog';
import { BlancoSwedenFormContextProvider } from 'src/contexts/BlancoSweden/BlancoSwedenFormContext';

import BlancoNorwayFormStepper from './BlancoNorwayFormStepper';
import BlancoNorwayStartPageSection from './sections/BlancoNorwayStartPageSection';

interface BlancoNorwayStartPageProps {}

const BlancoNorwayStartPage: FC<BlancoNorwayStartPageProps> = (props) => {
  const { } = props;

  return (
    <BlancoSwedenFormContextProvider>
      <BlancoNorwayStartPageSection />
      <StepperDialog>
        <BlancoNorwayFormStepper />
      </StepperDialog>
    </BlancoSwedenFormContextProvider>
  );
};


export default BlancoNorwayStartPage;
