import { FC } from 'react';

import { Grid,  Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import BlancoSwedenNewLoanPurpose from 'src/types/BlancoSweden/BlancoSwedenNewLoanPurpose';

import RefinanceAmountField from './RefinanceAmountField';
import StyledToggleButton from '../buttons/toggle/StyledToggleButton';
import StyledToggleButtonGroup from '../buttons/toggle/StyledToggleButtonGroup';

interface RefinanceFormFieldProps {
  name: string;
}

const RefinanceFormField: FC<RefinanceFormFieldProps> = (props) => {
  const { name } = props;

  const { values, setFieldValue, errors } = useFormikContext<{ [key: string]: any; }>();

  const error = errors.newLoanPurpose as string;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>
          Do you want to refinance your other loans?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <StyledToggleButtonGroup
          value={values[name]}
          exclusive
          onChange={(e, value) => {
            if (value !== null)
              setFieldValue(name, value);
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <StyledToggleButton value={true}>
                Yes
              </StyledToggleButton>
            </Grid>
            <Grid item xs={6}>
              <StyledToggleButton value={false}>
                No
              </StyledToggleButton>
            </Grid>
          </Grid>
        </StyledToggleButtonGroup>
      </Grid>
      <Grid item xs={12}>
        {!!values[name] && (
          <RefinanceAmountField name='refinanceAmount' />
        )}
        {!values[name] && (
          <StyledToggleButtonGroup
            value={values.newLoanPurpose}
            exclusive
            onChange={(e, value) => {
              if (value !== null)
                setFieldValue('newLoanPurpose', value);
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>
                  What is the purpose of the loan?
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <StyledToggleButton value={BlancoSwedenNewLoanPurpose.Consumption}>
                  Consumption
                </StyledToggleButton>
              </Grid>
              <Grid item xs={4}>
                <StyledToggleButton value={BlancoSwedenNewLoanPurpose.Car}>
                  Car
                </StyledToggleButton>
              </Grid>
              <Grid item xs={4}>
                <StyledToggleButton value={BlancoSwedenNewLoanPurpose.Health}>
                  Health
                </StyledToggleButton>
              </Grid>
              <Grid item xs={4}>
                <StyledToggleButton value={BlancoSwedenNewLoanPurpose.Investment}>
                  Investment
                </StyledToggleButton>
              </Grid>
              <Grid item xs={4}>
                <StyledToggleButton value={BlancoSwedenNewLoanPurpose.Travel}>
                  Travel
                </StyledToggleButton>
              </Grid>
              {error && (
                <Grid item xs={12}>
                  <Typography variant='caption' color='error'>
                    {error}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </StyledToggleButtonGroup>
        )}
      </Grid>
    </Grid>
  );
};


export default RefinanceFormField;
