import { FC, useEffect, useMemo, useState } from 'react';

import { Button, Grid, Link, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import FlexCenterBox from 'src/components/boxes/FlexCenterBox';
import GrConsentCheckbox from 'src/components/gr/GrConsentCheckbox';
import { useLoginContext } from 'src/contexts/auth/LoginContext';

interface GrFirstStartPageProps { }

const GrFirstStartPage: FC<GrFirstStartPageProps> = (props) => {
  const { } = props;

  const { t } = useTranslation();
  const { loginBankIdNo, loginError } = useLoginContext();

  const [consent, setConsent] = useState(false);
  const [consentError, setConsentError] = useState(false);

  const login = useMemo(() => () => {
    if (!consent) {
      setConsentError(true);

      return;
    }

    loginBankIdNo();
  }, [consent, loginBankIdNo]);

  useEffect(() => {
    if (consent) { setConsentError(false); }
  }, [consent]);

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Typography sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}>
          {t('Share your credit information')}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <GrConsentCheckbox
          value={consent}
          setValue={setConsent}
          error={consentError}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mb: 1 }}
      >
        <Typography sx={{ fontSize: 'small' }}>
          {/* eslint-disable react/no-unescaped-entities */}
          <Trans>
            You can easily manage your consent at
            {' '}
            <Link
              href="https://www.gjeldsregisteret.com/pages/innsyn-i-egne-opplysninger-og-administrasjon-av-samtykke"
              target="_blank"
              rel="noreferrer"
            >
              Gjeldsregisteret
            </Link>
            .{' '}
          </Trans>
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ mb: loginError ? 0 : 1 }}
      >
        <FlexCenterBox>
          <Button
            variant='contained'
            onClick={login}
          >
            {t('Retrieve My Credits')}
          </Button>
        </FlexCenterBox>
      </Grid>
      {loginError && (
        <Grid
          item
          xs={12}
          sx={{ mb: 1 }}
        >
          <FlexCenterBox>

            <Typography sx={{ fontSize: '0.75rem', color: 'error.main' }}>
              {t('There was an unexpected error logging in. Please try again.')}
            </Typography>
          </FlexCenterBox>
        </Grid>
      )}
      <Grid
        item
        xs={12}
      >
        <Typography sx={{ fontSize: 'small' }}>
          {/* eslint-disable react/no-unescaped-entities */}
          <Trans>
            By proceeding you agree to our
            {' '}
            <Link
              href="https://www.zensum.no/brukervilkar"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </Link>
            {' '}
            and
            {' '}
            <Link
              href="https://www.zensum.no/personvern"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </Link>
          </Trans>
        </Typography>
      </Grid>
    </Grid>
  );
};


export default GrFirstStartPage;
