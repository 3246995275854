import BlancoSwedenForm from 'src/types/BlancoSweden/form/BlancoSwedenForm';

const blancoSwedenFormDefaultData: BlancoSwedenForm = {
  startPage: {
    consent: false,
    loanAmount: 100000,
    newLoanPurpose: '',
    refinance: true,
    refinanceAmount: 10000,
    repaymentTime: 10,
  },
  family: {
    children: null,
    maritalStatus: '',
  },
  livingSituation: {
    livingCost: null,
    livingSituation: '',
  },
  income: {
    employmentType: null,
    yearlyIncome: null,
  },
  contact: {
    email: '',
    phone: '',
    pni: '',
  },
};

export default blancoSwedenFormDefaultData;
